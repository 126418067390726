import React, { useEffect, useState } from 'react';

import { keys } from 'lodash';
import PropTypes from 'prop-types';

import ComparisonFlow from './ComparisonFlow';
import {
  ANALYTICS_TRACKING_EVENT_NAMES,
  ANALYTICS_TRACKING_SCREEN_NAMES
} from '../../../../constants/tracking';
import { computePercentagesForFunnel } from '../../../../utils/businessMetrics';
import { getAnalyticsLanding } from '../../../../utils/helpers';
import storeAnalyticsMetadata from '../../../../utils/storeAnalyticsUsageMetadata';
import AnalyticsSpinner from '../../../Shared/AnalyticsSpinner';

import './Comparsion.css';

function FunnelAnalyticsComparison({
  primaryData,
  secondaryData,
  isLoading,
  firstLoad,
  showErrorContent,
  onComparisonApply
}) {
  const [primaryPercentages, setPrimaryPercentages] = useState(null);
  const [secondaryPercentages, setSecondaryPercentages] = useState(null);
  const [differences, setDifferences] = useState(null);

  const computeDifferences = () => {
    const primaryPercentages = computePercentagesForFunnel(primaryData, {});
    const secondaryPercentages = computePercentagesForFunnel(secondaryData, {});
    setPrimaryPercentages(primaryPercentages);
    setSecondaryPercentages(secondaryPercentages);

    const differences = keys(primaryPercentages).reduce((accumulator, key) => {
      accumulator[key] = (primaryPercentages[key] - secondaryPercentages[key]).toFixed(2);

      if (!accumulator[key]) accumulator[key] = 0;
      return accumulator;
    }, {});

    setDifferences(differences);
  };

  useEffect(() => {
    computeDifferences();
  }, [primaryData, secondaryData]);

  useEffect(() => {
    storeAnalyticsMetadata(
      ANALYTICS_TRACKING_EVENT_NAMES.BUSINESS_METRICS_COMPARISONS_PAGE_LOAD,
      ANALYTICS_TRACKING_SCREEN_NAMES.BUSINESS_METRICS_COMPARSIONS
    );
  }, []);

  if (firstLoad || showErrorContent) {
    return (
      <div className="comparisons-landing-container">
        {getAnalyticsLanding(showErrorContent, onComparisonApply)}
      </div>
    );
  }

  return (
    <div className="funnel-comparsion-container">
      <h4>Comparsion View</h4>
      <p>Here&apos;s a view of how your users are converted across A vs B</p>
      {isLoading ? (
        <div className="comparisons-loading-div">
          <AnalyticsSpinner />
        </div>
      ) : (
        <ComparisonFlow
          differences={differences}
          primaryData={primaryData}
          primaryPercentages={primaryPercentages}
          secondaryData={secondaryData}
          secondaryPercentages={secondaryPercentages}
        />
      )}
    </div>
  );
}

FunnelAnalyticsComparison.defaultProps = {
  showErrorContent: false,
  onComparisonApply: () => {}
};

FunnelAnalyticsComparison.propTypes = {
  primaryData: PropTypes.object.isRequired,
  secondaryData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  firstLoad: PropTypes.bool.isRequired,
  showErrorContent: PropTypes.bool,
  onComparisonApply: PropTypes.func
};

export default FunnelAnalyticsComparison;
