import React from 'react';

import PropTypes from 'prop-types';

import { transformLinkDataToSelectOption } from '../../../utils/customReport';
import SearchSelect from '../../Shared/SearchSelect';

function Filters({ customMetabaseLinks, selectedLinkData, setSelectedLinkData }) {
  const customLinkOptions = customMetabaseLinks.map((link) =>
    transformLinkDataToSelectOption(link)
  );

  return (
    <div className="custom-report-header-filters">
      <SearchSelect
        inputPlaceholder="Search Reports"
        options={customLinkOptions}
        selectedOption={transformLinkDataToSelectOption(selectedLinkData)}
        onOptionSelect={setSelectedLinkData}
      />
    </div>
  );
}

Filters.propTypes = {
  customMetabaseLinks: PropTypes.array.isRequired,
  selectedLinkData: PropTypes.object.isRequired,
  setSelectedLinkData: PropTypes.func.isRequired
};

export default Filters;
