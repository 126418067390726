import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import './StepwiseAnalytics.css';
import { Col, Row, Popover } from 'react-bootstrap';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import 'reactflow/dist/style.css';

import StepwiseFlow from './StepwiseFlow';
import HistoryIcon from '../../../../assets/icons/history.png';
import { APPLICATION_REDIRECT_STATUS } from '../../../../constants';
import { updateActiveHotspot } from '../../../../reducers/stepwise';
import ProgressDiv from '../../../Shared/ProgressDiv';
import CommonPopover from '../CommonPopover';

function StepwiseAnalytics({ filters, lastModified, loadingStepwise, loadingHotspots }) {
  const dispatch = useDispatch();
  const dropOff = useSelector((state) => state.stepwise.dropOffs);
  const highTime = useSelector((state) => state.stepwise.highTime);
  const highRetakes = useSelector((state) => state.stepwise.highRetakes);
  const backPressed = useSelector((state) => state.stepwise.backPressed);
  const renderGraph = useSelector((state) => state.stepwise.renderGraph);

  const getRedirectionUrl = () => {
    const { startDate, endDate } = filters && filters.dateRange ? filters.dateRange : {};
    const startDateEpoch = moment(startDate).valueOf();
    const endDateEpoch = moment(endDate).valueOf();
    return `${process.env.REACT_APP_AUDIT_PORTAL_BASE_URL}/applications?appId=${encodeURIComponent(
      filters.appId
    )}&workflowId=${encodeURIComponent(filters.workflowId)}&status=${encodeURIComponent(
      APPLICATION_REDIRECT_STATUS.join(',')
    )}&startDate=${encodeURIComponent(startDateEpoch)}&endDate=${encodeURIComponent(endDateEpoch)}`;
  };

  const redirectToApplications = () => {
    const redirectUrl = getRedirectionUrl();
    window.open(redirectUrl);
  };

  const handleHotspotClick = (event, elementId) => {
    event.stopPropagation();
    dispatch(updateActiveHotspot({ activeHotspot: elementId }));
  };

  return (
    <div className="stepwise-analytics-container">
      <Row className="stepwise-analytics-container-row">
        <Col
          xl={4}
          className="stepwise-analytics-container-col stepwise-analytics-container-col-stats">
          <ProgressDiv
            isLoading={loadingHotspots}
            title="Top steps with large % of drop-offs"
            subtitle="Insights into where customers might face issues"
            values={dropOff}
            onClick={handleHotspotClick}
            color="linear-gradient(to right, rgba(238, 210, 87, 0.8) 0%, rgba(238, 210, 87, 0.05) 100%)"
          />
          <ProgressDiv
            isLoading={loadingHotspots}
            title="Top steps that take a lot of time"
            subtitle="Insights into where customers get stuck"
            values={highTime}
            onClick={handleHotspotClick}
            color="linear-gradient(to right, #f1755c 0%, rgba(241, 117, 92, 0.05) 100%)"
          />
          <ProgressDiv
            isLoading={loadingHotspots}
            title="Top steps with high retries"
            subtitle="Insights into where customers might face issues"
            values={highRetakes}
            onClick={handleHotspotClick}
            color="linear-gradient(to right, #de4a70 0%, rgba(222, 74, 112, 0.05) 100%)"
          />
          <ProgressDiv
            isLoading={loadingHotspots}
            title="Top Steps with Back Button Clicks"
            subtitle="Insights into steps where users are clicking the back button "
            values={backPressed}
            onClick={handleHotspotClick}
            color="linear-gradient(to right, #B84BDE 0%, rgba(184, 75, 222, 0.20) 100%)"
          />
        </Col>
        <Col
          xl={8}
          className="stepwise-analytics-container-col stepwise-analytics-container-col-workflow">
          <div className="workflow-analytics-container">
            <div className="workflow-analytics-header-container">
              <h4>
                Step wise analytics
                {lastModified && (
                  <CommonPopover
                    trigger={['hover']}
                    popoverClass="funnel-popover"
                    body={
                      <Popover.Body>This workflow was last modified on {lastModified}</Popover.Body>
                    }
                    icon={HistoryIcon}
                  />
                )}
              </h4>
              {renderGraph && (
                <button type="button" onClick={() => redirectToApplications()}>
                  <FiExternalLink className="application-redirect-link-icon" />
                  View Dropped Off Applications
                </button>
              )}
            </div>
            <p>Overview on performance at each step. Click on a card to know more details</p>
            <StepwiseFlow isLoading={loadingStepwise} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

StepwiseAnalytics.defaultProps = {
  loadingStepwise: false,
  loadingHotspots: false
};

StepwiseAnalytics.propTypes = {
  filters: PropTypes.object.isRequired,
  lastModified: PropTypes.string.isRequired,
  loadingStepwise: PropTypes.bool,
  loadingHotspots: PropTypes.bool
};

export default StepwiseAnalytics;
