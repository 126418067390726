import React, { useState } from 'react';

import PropTypes from 'prop-types';
import ReactFlow, { ControlButton, Controls, ReactFlowProvider } from 'reactflow';

import StartIcon from '../../../../assets/icons/starticon.png';
import Modal from '../../../Shared/Modal';

function FullScreenModal({
  showFullScreenModal,
  hideFullScreen,
  nodes,
  edges,
  nodeTypes,
  getStartPosition
}) {
  const [fullScreenReactFlowInstance, setFullScreenReactFlowInstance] = useState(null);
  const getStartPositionFullScreen = (startNode) => {
    const { innerWidth: width, innerHeight: height } = window;
    const startPosition = getStartPosition(startNode, width, 0.9 * height);
    return startPosition;
  };

  const initialiseViewport = (event) => {
    event?.stopPropagation();
    if (fullScreenReactFlowInstance) {
      const viewport = getStartPositionFullScreen(nodes[0]);
      fullScreenReactFlowInstance.setViewport(viewport, { duration: 1000 });
    }
  };
  return (
    <Modal show={showFullScreenModal} onHide={hideFullScreen}>
      <Modal.Body>
        <ReactFlowProvider>
          <ReactFlow
            onInit={(instance) => setFullScreenReactFlowInstance(instance)}
            defaultViewport={getStartPositionFullScreen(nodes[0])}
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            zoomOnScroll={false}
            panOnScroll
            fitViewOptions={{ includeHiddenNodes: true }}
            maxZoom={1}
            minZoom={0.1}>
            <Controls showFitView={false} showInteractive={false}>
              <ControlButton type="button" title="Go to Start" onClick={initialiseViewport}>
                <img className="stepwise-start-icon" src={StartIcon} alt="Start Icon" />
              </ControlButton>
            </Controls>
          </ReactFlow>
        </ReactFlowProvider>
      </Modal.Body>
    </Modal>
  );
}

FullScreenModal.propTypes = {
  showFullScreenModal: PropTypes.bool.isRequired,
  hideFullScreen: PropTypes.func.isRequired,
  nodes: PropTypes.array.isRequired,
  edges: PropTypes.array.isRequired,
  nodeTypes: PropTypes.object.isRequired,
  getStartPosition: PropTypes.func.isRequired
};

export default FullScreenModal;
