import React, { useEffect, useRef } from 'react';

import ELK from 'elkjs';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ReactFlow, { MarkerType, ReactFlowProvider, useEdgesState, useNodesState } from 'reactflow';
import 'reactflow/dist/style.css';

import noData from '../../../../../assets/nodata.png';
import {
  edges as ComparisonEdges,
  nodes as ComparsionNodes
} from '../../../../../constants/funnelComparisonData';
import './ComparisonFlow.css';
import ComparisonCustomNode from '../CustomNode';

const nodeHeight = 40;
const nodeWidth = 150;

const nodeTypes = {
  custom: ComparisonCustomNode
};

const NODE_POSITIONS = {
  unique: { y: -360, x: 0 },
  completed: { y: -120, x: 0 },
  lost: { y: 40, x: 0 },
  manualReview: { y: 80, x: 0 },
  manualRejected: { y: -20, x: 0 },
  manualAccepted: { y: -20, x: 0 },
  autoRejected: { y: 60, x: 0 },
  autoApproved: { y: 20, x: 0 },
  error: { y: -20, x: 0 },
  pendingReview: { y: -40, x: 0 },
  dropOff: { y: -20, x: 0 }
};

const getLayoutedElements = async (
  nodes,
  edges,
  differences,
  primaryData,
  secondaryData,
  primaryPercentages,
  secondaryPercentages
) => {
  const elk = new ELK({});
  const amplifierRatio = Object.values(differences).some((val) => Math.abs(val) > 20) ? 1 : 12;
  const newNodes = nodes.map((node) => {
    return {
      ...node,
      width: nodeWidth,
      height: nodeHeight,
      data: {
        ...node.data,
        delta: differences[node.id],
        color: differences[node.id] >= 0 ? '#7fcca6' : '#f1755c',
        amplifierRatio,
        primaryValue: primaryData[node.id],
        secondaryValue: secondaryData[node.id],
        primaryPercentage: primaryPercentages[node.id],
        secondaryPercentage: secondaryPercentages[node.id]
      }
    };
  });
  const newEdges = edges.map((node) => ({
    ...node,
    sources: [node.source],
    targets: [node.target],
    markerEnd: {
      type: MarkerType.ArrowClosed
    }
  }));
  const graph = {
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'RIGHT',
      'elk.spacing.nodeNode': '180',
      'elk.layered.spacing.nodeNodeBetweenLayers': '140'
    },
    children: newNodes,
    edges: newEdges
  };
  const layout = await elk.layout(graph);
  const layoutNodes = layout.children.map((node) => {
    const nodeX = node.x;
    const nodeY = node.y;
    return {
      ...node,
      position: {
        x: nodeX + get(NODE_POSITIONS, [node.id, 'x'], 0),
        y: nodeY + get(NODE_POSITIONS, [node.id, 'y'], 0)
      }
    };
  });
  return { nodes: layoutNodes, edges: layout.edges };
};

function ComparisonELKFlow({
  differences,
  primaryData,
  secondaryData,
  primaryPercentages,
  secondaryPercentages
}) {
  const ref = useRef(null);

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const edgesWithUpdatedTypes = ComparisonEdges.map((edge) => {
    return edge;
  });

  const initialiseNodesAndEdges = async () => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = await getLayoutedElements(
      ComparsionNodes,
      ComparisonEdges,
      differences,
      primaryData,
      secondaryData,
      primaryPercentages,
      secondaryPercentages
    );

    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  };

  useEffect(() => {
    initialiseNodesAndEdges();
  }, [differences]);

  return (
    <div className="comparison_layout">
      <ReactFlow
        className="comparsion_layout_flow"
        ref={ref}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgesWithUpdatedTypes}
        maxZoom={1}
        minZoom={0.2}
        fitView={{ minZoom: 0.2 }}
        panOnScroll
        zoomOnScroll
      />
    </div>
  );
}

function ComparisonFlow({
  differences,
  primaryData,
  secondaryData,
  primaryPercentages,
  secondaryPercentages
}) {
  const showComparisons = (differences) =>
    Object.keys(differences).length > 0 && Object.values(differences).some((val) => val > 0);

  return (
    <ReactFlowProvider>
      {differences && showComparisons(differences) ? (
        <ComparisonELKFlow
          differences={differences}
          primaryData={primaryData}
          secondaryData={secondaryData}
          primaryPercentages={primaryPercentages}
          secondaryPercentages={secondaryPercentages}
        />
      ) : (
        <div className="comparison-no-data-container">
          <img src={noData} alt="No data" />
          <p>Not enough data to show insights here</p>
        </div>
      )}
    </ReactFlowProvider>
  );
}

ComparisonFlow.propTypes = {
  differences: PropTypes.object.isRequired,
  primaryData: PropTypes.object.isRequired,
  secondaryData: PropTypes.object.isRequired,
  primaryPercentages: PropTypes.object.isRequired,
  secondaryPercentages: PropTypes.object.isRequired
};

ComparisonELKFlow.propTypes = {
  differences: PropTypes.object.isRequired,
  primaryData: PropTypes.object.isRequired,
  secondaryData: PropTypes.object.isRequired,
  primaryPercentages: PropTypes.object.isRequired,
  secondaryPercentages: PropTypes.object.isRequired
};

export default ComparisonFlow;
