/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import _ from 'lodash';

import { ReactComponent as ApproveIcon } from '../../../../assets/icons/approve.svg';
import { ReactComponent as CkycIcon } from '../../../../assets/icons/ckycIcon.svg';
import { ReactComponent as UserCancelledIcon } from '../../../../assets/icons/decline.svg';
import { ReactComponent as DedupeIcon } from '../../../../assets/icons/dedupe.svg';
import { ReactComponent as DigilockerIcon } from '../../../../assets/icons/digilockerIcon.svg';
import { ReactComponent as FaceAuthIcon } from '../../../../assets/icons/faceAuth.svg';
import { ReactComponent as FaceMatchIcon } from '../../../../assets/icons/faceMatch.svg';
import {
  ReactComponent as AmlIcon,
  ReactComponent as IdCardIcon
} from '../../../../assets/icons/idValidation.svg';
import { ReactComponent as IpGeoIcon } from '../../../../assets/icons/ipGeoIcon.svg';
import { ReactComponent as OkycIcon } from '../../../../assets/icons/okycIcon.svg';
import { ReactComponent as PoaIcon } from '../../../../assets/icons/poaIcon.svg';
import { ReactComponent as DeclineIcon } from '../../../../assets/icons/Reject.svg';
import { ReactComponent as ReviewIcon } from '../../../../assets/icons/review.svg';
import { ReactComponent as SelfieIcon } from '../../../../assets/icons/selfieVerification.svg';
import { ReactComponent as StartIcon } from '../../../../assets/icons/start.svg';
import { ReactComponent as TextMatchIcon } from '../../../../assets/icons/textMatchIcon.svg';

export const arrayToJson = (arr) => {
  const obj = {};
  arr.forEach((element) => {
    obj[element.id] = element;
  });
  return obj;
};

// Convert null to "0", this is done because otherwise edges show NaN.
const formatStepwiseData = (stepwiseData) => {
  if (!stepwiseData) return stepwiseData;
  Object.keys(stepwiseData).forEach((key) => {
    if (!stepwiseData[key]) stepwiseData[key] = '0';
  });
  return stepwiseData;
};

export const calculatePercentage = (value, total) => {
  if (!total) {
    return 0;
  }
  let parsedValue = parseFloat(value);
  const parsedTotal = parseFloat(total);

  if (value === null) {
    parsedValue = 0;
  }
  if (Number.isNaN(parsedValue) || Number.isNaN(parsedTotal)) {
    return 0;
  }
  const percentage = (parsedValue / parsedTotal) * 100;
  return Number.isNaN(percentage) ? 0 : percentage.toFixed(0);
};

function calculateDifferencPercentage(source, target, stepwiseData) {
  if (source === 'start') {
    return `100%`;
  }
  const nextValue = _.get(stepwiseData, `${source}.uniqueUsers`, undefined);
  const lastValue = _.get(stepwiseData, `${target}.uniqueUsers`, undefined);
  const percentage = calculatePercentage(lastValue, nextValue);
  return percentage ? `${percentage}%` : '0%';
}

const getPercentage = (primary, secondary) => {
  let returnVal;
  if (primary || primary === 0) {
    returnVal = `${primary}%`;
  } else {
    if (Number.isNaN(secondary) || secondary === undefined || secondary === null) return '0%';
    returnVal = `${100 - secondary}%`;
  }

  return returnVal;
};

// BFS Algorithm to recursively convert the config into nodes and edges
export default function convertToNodesEdges(config, stepwiseData) {
  const { modules, conditions } = config;
  const modulesJson = arrayToJson(modules);
  const nodes = [];
  const edges = [];
  modules.unshift({
    id: 'start',
    nextStep: modules.length > 0 && modules[0].id,
    properties: {},
    subType: 'start',
    type: 'output',
    stepwiseNodeData:
      modules.length > 0 && formatStepwiseData(_.get(stepwiseData, `${modules[0].id}`))
  });
  const totalUsers = _.get(modules[0], 'stepwiseNodeData.uniqueUsers');
  const queue = [
    {
      ...modules[0],
      x: 0,
      level: 0
    }
  ];
  const completedNodeIds = [];
  while (queue.length > 0) {
    const module = queue.shift();
    const {
      id,
      nextStep,
      type,
      conditionType,
      stepwiseNodeData = formatStepwiseData(_.get(stepwiseData, `${module.id}`))
    } = module;
    // eslint-disable-next-line no-continue
    if (completedNodeIds.includes(id)) continue;
    completedNodeIds.push(id);
    // check if module is condition
    if (type === 'condition') {
      const { if_true, if_false } = module;
      let passNodeId;
      let failNodeId;
      let trueStepwisePercentage;
      let falseStepwisePercentage;
      const trueEdgeColor = {
        bg: '#f2fcf9',
        label: '#064'
      };
      const falseEdgeColor = {
        bg: '#f2fcf9',
        label: '#064'
      };
      nodes.push({
        id,
        nodeType: module.subType,
        stepwiseNodeData: formatStepwiseData(stepwiseNodeData),
        data: {
          subType: module.subType
        }
      });
      if (conditions[if_false]) {
        failNodeId = if_false;
        queue.push({
          ...conditions[if_false],
          id: failNodeId,
          type: 'condition',
          subType: 'condition',
          stepwiseNodeData: formatStepwiseData(stepwiseNodeData),
          conditionType: false
        });
      } else if (modulesJson[if_false]) {
        failNodeId = modulesJson[if_false].id;
        queue.push({
          ...modulesJson[if_false],
          id: failNodeId,
          conditionType: false
        });
        const dropOff = totalUsers - _.get(stepwiseData, `${if_false}.uniqueUsers`, 0);
        modulesJson[if_false].dropOff = calculatePercentage(dropOff, totalUsers);
        falseStepwisePercentage = calculatePercentage(
          _.get(stepwiseData, `${if_false}.uniqueUsers`, 0),
          totalUsers
        );
      } else {
        failNodeId = `${if_false}_${id}`;
        nodes.push({
          nodeType: if_false,
          id: failNodeId,
          stepwiseNodeData: formatStepwiseData(stepwiseNodeData),
          conditionType: false,
          data: {
            subType: module.subType
          }
        });
        if (if_false === 'manualReview') {
          const reviewCount = _.get(stepwiseNodeData, 'manualReview');
          falseStepwisePercentage = calculatePercentage(`${reviewCount}`, totalUsers);
          falseEdgeColor.bg = '#fff4dc';
          falseEdgeColor.label = '#9a4c24';
        }
        if (if_false === 'approve') {
          const approveCount =
            totalUsers -
            (_.get(stepwiseNodeData, 'manualReview') + _.get(stepwiseNodeData, 'autoRejected'));
          falseStepwisePercentage = calculatePercentage(`${approveCount}`, totalUsers);
        }
        if (if_false === 'decline') {
          const declineCount = _.get(stepwiseNodeData, 'autoRejected');
          falseStepwisePercentage = calculatePercentage(`${declineCount}`, totalUsers);
          falseEdgeColor.bg = '#ffebe6';
          falseEdgeColor.label = '#bf2600';
        }
      }
      if (conditions[if_true]) {
        passNodeId = if_true;
        queue.push({
          ...conditions[if_true],
          id: passNodeId,
          type: 'condition',
          subType: 'condition',
          stepwiseNodeData: formatStepwiseData(stepwiseNodeData),
          conditionType: true
        });
      } else if (modulesJson[if_true]) {
        passNodeId = modulesJson[if_true].id;
        queue.push({
          ...modulesJson[if_true],
          id: passNodeId,
          conditionType: true
        });
        const dropOff = totalUsers - _.get(stepwiseData, `${if_true}.uniqueUsers`, 0);
        modulesJson[if_true].dropOff = calculatePercentage(dropOff, totalUsers);
        trueStepwisePercentage = calculatePercentage(
          _.get(stepwiseData, `${if_true}.uniqueUsers`, 0),
          totalUsers
        );
      } else {
        passNodeId = `${if_true}_${id}`;
        nodes.push({
          id: passNodeId,
          nodeType: if_true,
          stepwiseNodeData: formatStepwiseData(stepwiseNodeData),
          conditionType: true,
          data: {
            subType: module.subType
          }
        });
        if (if_true === 'manualReview') {
          const reviewCount = _.get(stepwiseNodeData, 'manualReview');
          trueStepwisePercentage = calculatePercentage(`${reviewCount}`, totalUsers);
          trueEdgeColor.bg = '#fff4dc';
          trueEdgeColor.label = '#9a4c24';
        }
        if (if_true === 'approve') {
          const approveCount =
            totalUsers -
            (_.get(stepwiseNodeData, 'manualReview') + _.get(stepwiseNodeData, 'autoRejected'));
          trueStepwisePercentage = calculatePercentage(`${approveCount}`, totalUsers);
        }
        if (if_true === 'decline') {
          const declineCount = _.get(stepwiseNodeData, 'autoRejected');
          trueStepwisePercentage = calculatePercentage(`${declineCount}`, totalUsers);
          trueEdgeColor.bg = '#ffebe6';
          trueEdgeColor.label = '#bf2600';
        }
      }
      edges.push(
        {
          id: `${id}_${passNodeId}`,
          source: id,
          target: passNodeId,
          type: 'conditionEdge',
          animated: false,
          data: { branch: 'Current Branch' },
          label: getPercentage(trueStepwisePercentage, falseStepwisePercentage),
          labelBgPadding: [6, 5, 3, 6],
          labelBgBorderRadius: '12px',
          labelBgStyle: { fill: trueEdgeColor.bg },
          labelStyle: {
            fill: trueEdgeColor.label,
            fontWeight: 500,
            fontFamily: 'Inter',
            fontSize: '12px',
            margin: '17px 11px 27px 68px',
            textAlign: 'center'
          }
        },
        {
          id: `${id}_${failNodeId}`,
          source: id,
          target: failNodeId,
          type: 'conditionEdge',
          animated: false,
          data: { branch: 'New Branch' },
          label: getPercentage(falseStepwisePercentage, trueStepwisePercentage),
          labelBgPadding: [6, 5, 3, 6],
          labelBgBorderRadius: '12px',
          labelBgStyle: { fill: falseEdgeColor.bg },
          labelStyle: {
            fill: falseEdgeColor.label,
            fontWeight: 500,
            fontFamily: 'Inter',
            fontSize: '12px',
            margin: '17px 11px 27px 68px',
            textAlign: 'center'
          }
        }
      );
    } else if (type !== 'condition') {
      nodes.push({
        nodeType: module.subType,
        id,
        stepwiseNodeData: formatStepwiseData(stepwiseNodeData),
        conditionType,
        data: {
          subType: module.subType,
          dropOff: modulesJson[id]?.dropOff
        }
      });
      let targetNodeId;
      if (modulesJson[nextStep]) {
        targetNodeId = modulesJson[nextStep].id;
        queue.push({
          ...modulesJson[nextStep],
          id: targetNodeId
        });
        const dropOff = totalUsers - _.get(stepwiseData, `${nextStep}.uniqueUsers`, 0);
        modulesJson[nextStep].dropOff = calculatePercentage(dropOff, totalUsers);
      } else if (conditions[nextStep]) {
        targetNodeId = nextStep;
        queue.push({
          ...conditions[nextStep],
          id: targetNodeId,
          stepwiseNodeData: formatStepwiseData(stepwiseNodeData),
          type: 'condition',
          subType: 'condition'
        });
      } else {
        targetNodeId = `${nextStep}_${id}`;
        nodes.push({
          id: targetNodeId,
          nodeType: nextStep,
          stepwiseNodeData: formatStepwiseData(stepwiseNodeData),
          data: {
            subType: module.subType
          }
        });
      }
      edges.push({
        id: `${id}_${targetNodeId}`,
        source: id,
        target: targetNodeId,
        type: 'moduleEdge',
        animated: false,
        labelBgPadding: [6, 5, 3, 6],
        label: calculateDifferencPercentage(id, targetNodeId, stepwiseData),
        labelBgBorderRadius: '12px',
        labelBgStyle: { fill: '#f2fcf9' },
        labelStyle: {
          fill: '#064',
          fontWeight: 500,
          fontFamily: 'Inter',
          fontSize: '12px',
          margin: '17px 11px 27px 68px',
          textAlign: 'center'
        }
      });
      if (conditions[nextStep]) {
        edges[edges.length - 1] = {
          ...edges[edges.length - 1],
          fromCondition: true,
          labelBgPadding: [6, 5, 3, 6],
          label: `100%`,
          labelBgBorderRadius: '12px',
          labelBgStyle: { fill: '#f2fcf9' },
          labelStyle: {
            fill: '#064',
            fontWeight: 500,
            fontFamily: 'Inter',
            fontSize: '12px',
            margin: '17px 11px 27px 68px',
            textAlign: 'center'
          }
        };
      }
    }
  }

  // Adding all common edge properties
  edges.forEach((edge) => {
    if (!edge.type) edge.type = 'step';
    edge.style = {};
    edge.style.stroke = 'rgba(5, 5, 82, 0.2)';
  });

  return { nodes, edges };
}

export const convertSecondsToMinutesSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.ceil(seconds % 60);
  return `${minutes}m ${remainingSeconds}s`;
};

export const convertMstoMinutesSeconds = (milliseconds) => {
  if (milliseconds === '-') {
    return `- -`;
  }
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
};

const DefaultIcon = IdCardIcon;

const typeToIconMapping = {
  type1: <SelfieIcon />,
  type2: <IdCardIcon />,
  type3: <IdCardIcon />,
  type4: <FaceMatchIcon />,
  type5: <AmlIcon />
};

const subTypeIconMapping = {
  id_card_verification: <IdCardIcon />,
  id_card_validation: <IdCardIcon />,
  selfie_verification: <SelfieIcon />,
  selfie_validation: <SelfieIcon />,
  selfie_id_match_api: <FaceMatchIcon />,
  face_auth_api: <FaceAuthIcon />,
  face_dedupe_api: <DedupeIcon />,
  aml_search_api: <AmlIcon />,
  start: <StartIcon />,
  decline: <DeclineIcon />,
  digilocker_aadhaar_phone_link_form: <DigilockerIcon />,
  digilocker_details_api: <DigilockerIcon />,
  digilocker_form: <DigilockerIcon />,
  digilocker_url_api: <DigilockerIcon />,
  digilocker_webview: <DigilockerIcon />,
  geoIp_api: <IpGeoIcon />,
  ind_central_db_checks_api: <CkycIcon />,
  ind_digilocker_flow: <DigilockerIcon />,
  ind_digilocker_form: <DigilockerIcon />,
  ind_okyc_flow: <OkycIcon />,
  ind_okyc_form: <OkycIcon />,
  ip_geolocation_api: <IpGeoIcon />,
  poa_validation: <PoaIcon />,
  text_match_api: <TextMatchIcon />,
  manualReview: <ReviewIcon />,
  approve: <ApproveIcon />,
  user_cancelled: <UserCancelledIcon />,
  ...typeToIconMapping
};

export const getIconForSubType = (subType) => _.get(subTypeIconMapping, subType, <DefaultIcon />);
