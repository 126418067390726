import React from 'react';

import './HoverDetails.css';
import PropTypes from 'prop-types';

import HDRow from './HDRow';

function HoverDetails({ title, tableConfig }) {
  return (
    <div className="hover-details-container">
      <div className="hover-details-header-div">
        <h4>{title} Metrics</h4>
      </div>
      <div className="hover-details-data-div">
        {tableConfig.map((row) => {
          return (
            <HDRow
              key={row.label}
              title={row.label}
              value={row.value}
              haveBottomSpacing={row.spacing}
            />
          );
        })}
      </div>
    </div>
  );
}

HoverDetails.propTypes = {
  title: PropTypes.string.isRequired,
  tableConfig: PropTypes.array.isRequired
};

export default HoverDetails;
