import { useEffect, useState } from 'react';

import { has, get, keys } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  updateCredentials,
  updateCurrentAppId,
  updateCurrentClientId,
  updateCurrentUseCase
} from '../reducers/user';

const useFilters = ({ dispatchUpdates = true }) => {
  const dispatch = useDispatch();
  const allAppIds = useSelector((state) => state.user.userDetails.appIds);
  const credentials = useSelector((state) => state.user.credentials);
  const currentClientId = useSelector((state) => state.user.currentClientId);
  const currentUseCase = useSelector((state) => state.user.currentUseCase);
  const currentEnvironment = useSelector((state) => state.user.currentEnvironment);

  const [clientIds, setClientIds] = useState([]);
  const [useCases, setUseCases] = useState([]);
  const [appIds, setAppIds] = useState([]);

  const initializeFilters = () => {
    const credentialsObject = Object.keys(allAppIds).reduce((accumulator, appId) => {
      const { clientId, type, useCase } = allAppIds[appId];
      if (!has(accumulator, clientId)) {
        accumulator[clientId] = {};
      }
      if (!has(accumulator[clientId], useCase)) {
        accumulator[clientId][useCase] = {
          TESTING: {},
          STAGING: {},
          PRODUCTION: {}
        };
      }

      accumulator[clientId][useCase][type][appId] = 1;
      accumulator[clientId][useCase].STAGING = {
        ...accumulator[clientId][useCase].STAGING,
        ...accumulator[clientId][useCase].TESTING
      };
      return accumulator;
    }, {});
    const allClientIds = Object.keys(credentialsObject);

    setClientIds(allClientIds);
    dispatch(updateCredentials(credentialsObject));
    if (dispatchUpdates)
      dispatch(updateCurrentClientId(allClientIds.length ? allClientIds[0] : ''));
  };

  const updateUseCases = () => {
    const currentUseCases = keys(get(credentials, currentClientId));
    const activeUseCase = currentUseCases.length && currentUseCases[0];

    setUseCases(currentUseCases);

    if (activeUseCase) {
      if (dispatchUpdates) dispatch(updateCurrentUseCase(activeUseCase));
    }
  };

  const updateAppIds = () => {
    const currentAppIds = get(credentials, [currentClientId, currentUseCase, currentEnvironment]);
    const activeAppId = keys(currentAppIds).length ? keys(currentAppIds)[0] : '';

    setAppIds(currentAppIds);
    if (activeAppId && dispatchUpdates)
      dispatch(updateCurrentAppId({ appId: activeAppId, type: currentEnvironment }));
  };

  useEffect(() => {
    initializeFilters();
  }, [allAppIds]);

  useEffect(() => {
    updateUseCases();
  }, [credentials, currentClientId]);

  useEffect(() => {
    updateAppIds();
  }, [currentClientId, currentUseCase, currentEnvironment]);

  return { credentials, clientIds, useCases, appIds };
};

export default useFilters;
