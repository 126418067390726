import React from 'react';
import './NotFound.css';

function NotFound() {
  return (
    <div className="notfound-parent-div">
      <h3>Error 404: Not Found!</h3>
      <a href="/">Back To Home</a>
    </div>
  );
}

export default NotFound;
