import React, { useEffect, useState } from 'react';

import { Spinner } from 'clm-components';
import { get } from 'lodash';

import { getMetabaseStaticEmbeddingLinks } from '../../api/analytics';
import Body from '../../Components/PageComponents/CustomReport/Body';
import Header from '../../Components/PageComponents/CustomReport/Header';
import { CUSTOM_REPORT_ERROR_CODES } from '../../constants';
import { isNonEmptyArray } from '../../utils/helpers';

import './CustomReport.css';

function CustomReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLinkData, setSelectedLinkData] = useState({});
  const [customMetabaseLinks, setCustomMetabaseLinks] = useState([]);
  const [errorCode, setErrorCode] = useState(null);

  useEffect(() => {
    const initializeCustomDashboards = async () => {
      const { links, apiError } = await getMetabaseStaticEmbeddingLinks();
      setIsLoading(false);
      if (apiError) {
        setErrorCode(CUSTOM_REPORT_ERROR_CODES.metabase.STATIC_EMBEDDING_UNKNOWN_API_ERROR);
        return;
      }

      if (!isNonEmptyArray(links)) {
        setErrorCode(CUSTOM_REPORT_ERROR_CODES.metabase.NO_STATIC_EMBEDDING_LINKS_FOUND);
        return;
      }

      setSelectedLinkData(get(links, 0));
      setCustomMetabaseLinks(links);
    };

    setIsLoading(true);
    initializeCustomDashboards();
  }, []);

  if (isLoading) {
    return (
      <div className="custom-report-container loader">
        <Spinner size="meduim" />
      </div>
    );
  }

  return (
    <div className="custom-report-container">
      <Header
        errorCode={errorCode}
        customMetabaseLinks={customMetabaseLinks}
        selectedLinkData={selectedLinkData}
        setSelectedLinkData={setSelectedLinkData}
      />
      <Body errorCode={errorCode} selectedLinkData={selectedLinkData} />
    </div>
  );
}

export default CustomReport;
