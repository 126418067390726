import * as Sentry from '@sentry/react';
import axios from 'axios';

const getUserLocationDetails = async () => {
  try {
    const res = await axios.get('https://ipapi.co/json/', { withCredentials: false });
    return res;
  } catch (err) {
    Sentry.captureException(err);
    return err;
  }
};

export default getUserLocationDetails;
