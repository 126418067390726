import React from 'react';

import PropTypes from 'prop-types';

import './Tooltip.css';

function Tooltip({ text, tooltipId }) {
  return (
    <div id={tooltipId} className="analytics__tooltip">
      <p>{text}</p>
    </div>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  tooltipId: PropTypes.string.isRequired
};

export default Tooltip;
