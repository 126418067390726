import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ELKLayout from './ELKLayout';
import noDataImage from '../../../../../assets/nodata.png';
import 'reactflow/dist/base.css';
import './StepwiseFlow.css';
import AnalyticsSpinner from '../../../../Shared/AnalyticsSpinner';

function StepwiseFlow({ isLoading }) {
  const renderGraph = useSelector((state) => state.stepwise.renderGraph);
  return (
    <div className="layout-parent">
      {renderGraph ? (
        <ELKLayout />
      ) : (
        <div className="stepwise-empty-state">
          {isLoading ? (
            <AnalyticsSpinner />
          ) : (
            <>
              <img src={noDataImage} alt="No data" />
              <p>Not enough data to show insights</p>
            </>
          )}
        </div>
      )}
    </div>
  );
}

StepwiseFlow.defaultProps = {
  isLoading: false
};

StepwiseFlow.propTypes = {
  isLoading: PropTypes.bool
};

export default StepwiseFlow;
