import React from 'react';

import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Popover } from 'react-bootstrap';

import InfoIcon from '../../../../../assets/InfoIcon.svg';
import { formatDateForDropdown } from '../../../../../utils/formatDate';
import CommonPopover from '../../CommonPopover';

const TEXT = {
  manualReview: {
    title: 'Manual Review',
    tooltip:
      "Applications that reached any of 'Needs review', 'Manually approved' or 'Manually declined' statuses in any one of their attempts"
  },
  autoRejected: {
    title: 'Auto Rejected',
    tooltip: "Applications that reached 'Auto Rejected' status in any one of their attempts"
  },
  error: {
    title: 'Error',
    tooltip: "Applications that reached 'Error' status in any one of their attempts"
  }
};

function Header({ filters, statusSelected }) {
  return (
    <div className="hotspot__side_panel__header__container">
      <div className="hotspot__side_panel__title">
        <h1>Drill Down - {get(TEXT, [statusSelected, 'title'])}</h1>
        <CommonPopover
          trigger={['hover']}
          body={<Popover.Body>{get(TEXT, [statusSelected, 'tooltip'])}</Popover.Body>}
          icon={InfoIcon}
        />
      </div>
      <p>
        Date Range is &quot;
        {formatDateForDropdown(
          filters?.dateRange?.startDate,
          filters?.dateRange?.endDate
        )}&quot; <span>Where</span> Business Unit is &quot;
        {filters.useCase}&quot; <span>&</span> App ID is &quot;{filters.appId}&quot; <span>&</span>{' '}
        Workflow ID is &quot;{filters.workflowId}&quot;
      </p>
    </div>
  );
}

Header.propTypes = {
  filters: PropTypes.object.isRequired,
  statusSelected: PropTypes.string.isRequired
};

export default Header;
