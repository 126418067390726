/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { endOfDay, startOfDay } from 'date-fns';
import { get, keys } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getWorkflowsV2 } from '../../../api/workflow';
import RefreshIcon from '../../../assets/icons/refresh.svg';
import useFilters from '../../../hooks/useFilters';
import { shouldRenderDropdown } from '../../../utils/helpers';
import DatePicker from '../DatePicker';
import Dropdown from '../Dropdown';

import './Filters.css';

function AnalyticsFilters({
  filters,
  updateFilters,
  dispatchUpdates,
  showAllWorkflowsOption,
  debugMode,
  displayApplyButton,
  onFiltersApply,
  secondaryFilters,
  isLoading,
  displayRefreshButton
}) {
  const allCredentials = useSelector((state) => state.user.credentials);

  const { clientIds, useCases } = useFilters({ dispatchUpdates });

  const [loadingWorkflows, setLoadingWorkflows] = useState(false);
  const [credentials, setCredentials] = useState([]);
  const [haveFiltersChanged, setHaveFiltersChanged] = useState(true);
  const [workflows, setWorkflows] = useState([]);
  const [currentUseCases, setCurrentUseCases] = useState(useCases);

  const fetchWorkflows = async ({ appId }) => {
    setLoadingWorkflows(true);
    try {
      let workflowIds = [];
      if (showAllWorkflowsOption) workflowIds.push('All Workflows');
      const allWorkflows = (await getWorkflowsV2(appId)).workflows.sort((a, b) => {
        return a.localeCompare(b, 'en', { sensitivity: 'base' });
      });
      workflowIds = [...workflowIds, ...allWorkflows];
      setWorkflows(workflowIds);
      updateFilters((prev) => ({ ...prev, workflowId: workflowIds[0] }));
    } catch (error) {
      Sentry.captureException(error);
    }
    setLoadingWorkflows(false);
  };

  const getCredentials = async ({ clientId, useCase, appId }) => {
    if (appId) {
      await fetchWorkflows({ appId });
      updateFilters((prev) => ({ ...prev, appId }));
      return;
    }

    const useCaseCredentials = get(allCredentials, [clientId, useCase]);
    const allEnvironmentCredentials = [
      ...keys(useCaseCredentials?.TESTING),
      ...keys(useCaseCredentials?.PRODUCTION),
      ...keys(useCaseCredentials?.STAGING)
    ];
    const uniqueCredentials = [...new Set(allEnvironmentCredentials)];
    setCredentials(uniqueCredentials);
    updateFilters((prev) => {
      return { ...prev, appId: uniqueCredentials[0], clientId, useCase };
    });
    await fetchWorkflows({ appId: uniqueCredentials[0] });
    setHaveFiltersChanged(false);
  };

  const handleUseCaseChange = ({ clientId, useCase }) => getCredentials({ clientId, useCase });

  const handleClientIdChange = ({ clientId }) => {
    const currentUseCases = keys(get(allCredentials, clientId));
    const activeUseCase = currentUseCases.length && currentUseCases[0];

    setCurrentUseCases(currentUseCases);

    if (activeUseCase) {
      handleUseCaseChange({ clientId, useCase: activeUseCase });
    }
  };

  const handleFilterValueChange = (name, value) => {
    switch (name) {
      case 'clientId':
        handleClientIdChange({ clientId: value });
        break;

      case 'useCase':
        handleUseCaseChange({ clientId: filters.clientId, useCase: value });
        break;

      case 'appId':
        getCredentials({ clientId: filters.clientId, useCase: filters.useCase, appId: value });
        break;

      case 'workflowId':
        updateFilters((prev) => ({ ...prev, workflowId: value }));
        break;

      case 's3VersionId':
        updateFilters((prev) => ({ ...prev, s3VersionId: value }));
        break;

      default:
        console.log('Invalid');
    }
  };

  const changeDateRange = (event) => {
    updateFilters((prev) => {
      return {
        ...prev,
        dateRange: {
          startDate: startOfDay(event.range1.startDate),
          endDate: endOfDay(event.range1.endDate)
        }
      };
    });
  };

  const onFilterChange = (event) => {
    const { name, value } = event.target;
    handleFilterValueChange(name, value);
  };

  const initialiseFilters = () => {
    updateFilters((prev) => ({ ...prev, clientId: clientIds[0] }));
    handleClientIdChange({ clientId: clientIds[0] });
  };

  const handleApplyClick = () => {
    setHaveFiltersChanged(false);
    onFiltersApply();
  };

  const handleRefresh = () => {
    onFiltersApply();
  };

  useEffect(() => {
    initialiseFilters();
  }, [allCredentials]);

  useEffect(() => {
    setHaveFiltersChanged(true);
  }, [filters, secondaryFilters]);

  return (
    <div className="analytics-filters-main">
      {!debugMode && <DatePicker filters={filters} changeDateRange={changeDateRange} />}
      {debugMode && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            name="debugModeStartDate"
            value={filters?.dateRange?.startDate}
            className="debug-mode-filter-input"
            onAccept={(newValue) =>
              updateFilters((prev) => ({
                ...prev,
                dateRange: { ...prev.dateRange, startDate: newValue }
              }))
            }
          />
        </LocalizationProvider>
      )}
      {debugMode && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            name="debugModeEndDate"
            value={filters?.dateRange?.endDate}
            className="debug-mode-filter-input"
            onAccept={(newValue) =>
              updateFilters((prev) => ({
                ...prev,
                dateRange: { ...prev.dateRange, endDate: newValue }
              }))
            }
          />
        </LocalizationProvider>
      )}
      {shouldRenderDropdown(clientIds) && (
        <Dropdown
          label="Client ID:"
          placeholder="Choose a Client ID"
          options={clientIds}
          value={filters?.clientId}
          name="clientId"
          onChange={handleFilterValueChange}
        />
      )}
      {shouldRenderDropdown(currentUseCases) && (
        <Dropdown
          label="BU ID:"
          placeholder="Choose a Use Case"
          options={currentUseCases}
          value={filters?.useCase}
          name="useCase"
          onChange={handleFilterValueChange}
        />
      )}
      <Dropdown
        label="App ID: "
        placeholder="Choose an App ID"
        options={credentials}
        value={filters?.appId}
        name="appId"
        onChange={handleFilterValueChange}
      />
      {shouldRenderDropdown(workflows, 0) && (
        <Dropdown
          label="Workflow ID: "
          placeholder="Choose a Workflow ID"
          options={workflows}
          value={filters?.workflowId}
          name="workflowId"
          onChange={handleFilterValueChange}
        />
      )}
      {debugMode && (
        <input
          name="s3VersionId"
          className="debug-mode-filter-input"
          type="text"
          placeholder="Enter Workflow S3 Version ID"
          onChange={onFilterChange}
        />
      )}
      {displayApplyButton && haveFiltersChanged && !isLoading && (
        <button
          disabled={loadingWorkflows}
          className="analytics-filters-apply-button"
          onClick={handleApplyClick}
          type="button">
          Apply
        </button>
      )}
      {displayRefreshButton && !haveFiltersChanged && !isLoading && (
        <button
          disabled={loadingWorkflows || isLoading}
          type="button"
          onClick={handleRefresh}
          className="analytics-filters-refresh-button">
          <img src={RefreshIcon} alt="refresh-icon" />
        </button>
      )}
    </div>
  );
}

AnalyticsFilters.defaultProps = {
  dispatchUpdates: true,
  showAllWorkflowsOption: false,
  debugMode: false,
  displayApplyButton: true,
  secondaryFilters: {},
  isLoading: false,
  displayRefreshButton: true
};

AnalyticsFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  dispatchUpdates: PropTypes.bool,
  showAllWorkflowsOption: PropTypes.bool,
  debugMode: PropTypes.bool,
  displayApplyButton: PropTypes.bool,
  onFiltersApply: PropTypes.func.isRequired,
  secondaryFilters: PropTypes.object,
  isLoading: PropTypes.bool,
  displayRefreshButton: PropTypes.bool
};

export default AnalyticsFilters;
