import { formatDateForApplicationTable } from '../../../../../../utils/formatDate';

export const TABLE_COLUMNS = [
  {
    id: 'transaction_id',
    label: 'Transaction ID'
  },
  {
    id: 'created_at',
    label: 'Created At'
  },
  {
    id: 'status',
    label: 'Status'
  }
];

const showApplicationStatus = (val) => {
  const statusInfo = {};
  switch (val) {
    case 'APPROVED_BY_AGENT':
    case 'manually_approved':
      statusInfo.status = 'manually approved';
      statusInfo.style = 'approved';
      break;
    case 'DECLINED_BY_AGENT':
    case 'manually_declined':
      statusInfo.status = 'manually declined';
      statusInfo.style = 'declined';
      break;
    case 'APPLICATION_IN_PROGRESS':
    case 'started':
      statusInfo.status = 'started';
      statusInfo.style = 'applying';
      break;
    case 'NEEDS_REVIEW':
    case 'needs_review':
      statusInfo.status = 'needs review';
      statusInfo.style = 'review';
      break;
    case 'AUTO_APPROVED':
    case 'auto_approved':
      statusInfo.status = 'auto approved';
      statusInfo.style = 'approved';
      break;
    case 'AUTO_DECLINED':
    case 'AUTO_REJECT':
    case 'auto_declined':
      statusInfo.status = 'auto declined';
      statusInfo.style = 'declined';
      break;
    case 'USER_DROPPED_OFF':
    case 'user_cancelled':
      statusInfo.status = 'user cancelled';
      statusInfo.style = 'dropped';
      break;
    case 'kyc_in_progress':
      statusInfo.status = 'kyc in progress';
      statusInfo.style = 'dropped';
      break;
    case 'error':
      statusInfo.status = 'error';
      statusInfo.style = 'dropped';
      break;
    default:
      statusInfo.status = 'reported by agent';
      statusInfo.style = 'reported';
      break;
  }
  return statusInfo;
};

export const generateTableRows = (data) => {
  return data.map((row) => {
    const tableData = {};

    tableData.transaction_id = {
      value: row.transaction_id,
      componentRenderer: () => <p>{row.transaction_id}</p>
    };

    tableData.created_at = {
      value: row.transaction_start_timestamp,
      componentRenderer: () => (
        <p>{formatDateForApplicationTable(row.transaction_start_timestamp)}</p>
      )
    };

    tableData.status = {
      componentRenderer: () => (
        <span className={`application__status ${showApplicationStatus(row.status).style}`}>
          {showApplicationStatus(row.status).status}
        </span>
      )
    };

    return tableData;
  });
};
