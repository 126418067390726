import { JSONPath } from 'jsonpath-plus';
import { groupBy, has, keyBy, keys, values } from 'lodash';

// Group by super module ID and filter the normal modules
const getSuperModules = (modules) => {
  const groupedSuperModules = groupBy(modules, 'superModuleId');
  delete groupedSuperModules.undefined;
  return groupedSuperModules;
};

// Gets next steps for a condition
const getConditionNextSteps = (condition) => {
  return [condition.if_true, condition.if_false];
};

// Gets next steps for all other modules
const getModulesNextSteps = (module) => {
  if (!module) return [];

  let nextSteps = JSONPath({
    path: '$..nextStep',
    json: module
  });
  nextSteps = nextSteps?.filter((item) => item !== '');

  return nextSteps;
};

const isChildModule = (module) => {
  if (!module) return false;
  // TODO: Update the logic here post identifier is added to child modules
  const nextSteps = getModulesNextSteps(module);
  if (nextSteps.includes('dismissToParent')) return true;
  return false;
};

// Return the constitutent nodes for a super module
const getSuperModuleConstituentNodes = (
  startNodeId,
  exitNodeId,
  conditions,
  modules,
  constituentModules
) => {
  const constituentConditions = [];
  const queue = [];
  const accountedConditions = new Set();
  const accountedModules = new Set();

  const modulesById = keyBy(modules, 'id');

  queue.push(startNodeId);

  while (queue.length > 0) {
    const currentNodeId = queue.shift();
    // If the current node is not the exit node and the node is a condition we put it in the array
    // We ignore the normal modules since we already get the by grouping by super module ID
    if (currentNodeId !== exitNodeId && has(conditions, currentNodeId)) {
      if (!accountedConditions.has(currentNodeId)) {
        constituentConditions.push(currentNodeId);
        accountedConditions.add(currentNodeId);
      }
      queue.push(...getConditionNextSteps(conditions[currentNodeId]));
    } else if (
      currentNodeId !== exitNodeId &&
      has(modulesById, currentNodeId) &&
      !accountedModules.has(currentNodeId)
    ) {
      accountedModules.add(currentNodeId);
      const moduleNextSteps = getModulesNextSteps(modulesById[currentNodeId]);
      const filteredNextSteps = moduleNextSteps?.filter(
        (moduleId) => !isChildModule(modulesById[moduleId])
      );
      if (filteredNextSteps.length > 0) queue.push(...filteredNextSteps);
    }
  }

  const constituentModuleIds = constituentModules.map((module) => module.id);

  return [...constituentConditions, ...constituentModuleIds];
};

const deleteSuperModuleConstituentNodes = ({
  preprocessedWorkflowModules,
  preprocessedWorkflowConditions,
  superModuleConstituentNodeIds
}) => {
  const filteredModules = keyBy(preprocessedWorkflowModules, 'id');
  const filteredConditions = { ...preprocessedWorkflowConditions };
  superModuleConstituentNodeIds.forEach((nodeId) => {
    if (has(filteredModules, nodeId)) delete filteredModules[nodeId];
    else if (has(filteredConditions, nodeId)) delete filteredConditions[nodeId];
  });

  return { filteredModules: values(filteredModules), filteredConditions };
};

// Preprocess the workflow to club super modules
export const preprocessWorkflow = (workflow) => {
  const { properties, modules, conditions } = workflow;
  const { superModuleMetaData } = properties?.builder || {};

  // If there is no super module metadata return normal workflow
  if (!superModuleMetaData) return workflow;

  let preprocessedWorkflowModules = [...modules];
  let preprocessedWorkflowConditions = { ...conditions };
  const superModules = getSuperModules(modules);
  keys(superModules).forEach((superModuleId) => {
    const { startNodeId, exitNodeId, moduleName } = superModuleMetaData[superModuleId];
    const constituentModules = superModules[superModuleId];

    const superModuleConstituentNodeIds = getSuperModuleConstituentNodes(
      startNodeId,
      exitNodeId,
      conditions,
      modules,
      constituentModules
    );

    const { filteredModules, filteredConditions } = deleteSuperModuleConstituentNodes({
      preprocessedWorkflowModules,
      preprocessedWorkflowConditions,
      superModuleConstituentNodeIds
    });

    preprocessedWorkflowModules = [...filteredModules];
    preprocessedWorkflowConditions = { ...filteredConditions };

    preprocessedWorkflowModules.push({
      ...superModules[superModuleId][0],
      id: startNodeId,
      nextStep: exitNodeId,
      name: moduleName,
      type: 'superModule'
    });
  });
  return {
    ...workflow,
    modules: preprocessedWorkflowModules,
    conditions: preprocessedWorkflowConditions
  };
};

// Preprocess the stepwise data to compute data for super module
export const preprocessStepwiseData = (data, workflow) => {
  const { properties, modules, conditions } = workflow;
  const { superModuleMetaData } = properties?.builder || {};

  const preprocessedStepwiseData = { ...data };

  // If there is no super module metadata return the normal data
  if (!superModuleMetaData)
    return {
      preprocessedStepwiseData
    };

  const superModules = getSuperModules(modules);

  keys(superModules).forEach((superModuleId) => {
    const constituentModules = superModules[superModuleId];
    const { exitNodeId, startNodeId } = superModuleMetaData[superModuleId];
    const superModuleConstituentNodeIds = getSuperModuleConstituentNodes(
      startNodeId,
      exitNodeId,
      conditions,
      modules,
      constituentModules
    );

    const currentSuperModuleStepwiseData = {
      dropOff: 0,
      nextSteps: {
        [exitNodeId]: 0
      },
      medianAttempts: 0,
      medianTimeSpent: 0,
      transactionsEnded: 0,
      backPressed: 0,
      transactionsStarted: data[startNodeId]?.transactionsStarted
    };

    superModuleConstituentNodeIds.forEach((nodeId) => {
      // Removing the current node from stepwise data
      if (has(preprocessedStepwiseData, nodeId)) delete preprocessedStepwiseData[nodeId];

      const stepwiseData = data[nodeId];

      // If the current node does not have any data we do not have to process it
      if (!stepwiseData) return;

      currentSuperModuleStepwiseData.medianTimeSpent += stepwiseData.medianTimeSpent;
      currentSuperModuleStepwiseData.medianAttempts = Math.max(
        currentSuperModuleStepwiseData.medianAttempts,
        stepwiseData.medianAttempts
      );
      currentSuperModuleStepwiseData.nextSteps[exitNodeId] +=
        stepwiseData?.nextSteps[exitNodeId] || 0;
      currentSuperModuleStepwiseData.transactionsEnded += stepwiseData?.nextSteps[exitNodeId] || 0;
      currentSuperModuleStepwiseData.backPressed = Math.max(
        currentSuperModuleStepwiseData.backPressed,
        stepwiseData.backPressed
      );
    });

    currentSuperModuleStepwiseData.dropOff =
      currentSuperModuleStepwiseData.transactionsStarted -
      currentSuperModuleStepwiseData.transactionsEnded;

    preprocessedStepwiseData[startNodeId] = currentSuperModuleStepwiseData;
  });

  return {
    preprocessedStepwiseData
  };
};
