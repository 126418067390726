import * as Sentry from '@sentry/react';
import axios from 'axios';
import get from 'lodash/get';

import { checkTokenExpiry } from '../actions/user';

const getWorkflowRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/workflow`,
  withCredentials: true
});

const getWorkflowRequestV2 = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_BASE_URL}/api/v2/workflow`,
  withCredentials: true
});

const getWorkflow = async (appId, workflowId) => {
  try {
    const res = await getWorkflowRequest.post(
      '/id',
      { workflowId },
      {
        headers: {
          appId
        }
      }
    );
    return res.data.result;
  } catch (err) {
    checkTokenExpiry(get(err, 'response.status'));
    Sentry.captureException(err);
    return err;
  }
};

export const getWorkflowV2 = async ({ appId, workflowId, s3VersionId }) => {
  try {
    const res = await getWorkflowRequestV2.post(
      '/id',
      { appId, workflowId, s3VersionId },
      {
        headers: {
          appId
        }
      }
    );
    return res.data.result;
  } catch (err) {
    checkTokenExpiry(get(err, 'response.status'));
    Sentry.captureException(err);
    return err;
  }
};

export const getWorkflows = async (appId) => {
  try {
    const res = await getWorkflowRequest.get('/', {
      headers: {
        appId
      }
    });
    return res.data.result;
  } catch (err) {
    checkTokenExpiry(get(err, 'response.status'));
    Sentry.captureException(err);
    return err;
  }
};

export const getWorkflowsV2 = async (appId) => {
  try {
    const res = await getWorkflowRequestV2.post('/list/app-id', { appId });
    return res.data.result;
  } catch (err) {
    checkTokenExpiry(get(err, 'response.status'));
    Sentry.captureException(err);
    return err;
  }
};

export const getWorkflowModules = async (appId) => {
  try {
    const res = await getWorkflowRequest.get('/modules', {
      headers: {
        appId
      }
    });
    return res.data.result;
  } catch (err) {
    checkTokenExpiry(get(err, 'response.status'));
    Sentry.captureException(err);
    return err;
  }
};

export default getWorkflow;
