import React, { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import Filter from './Filter';
import KnowMoreModal from './KnowMoreModal';
import Table from './Table';
import { getFunnelHotspotTransactions } from '../../../../../api/analytics';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/InfoIcon2.svg';

function Body({ currentStatusHotspots, activeHotspot, filters, statusSelected }) {
  const [transactionData, setTransactionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const displayKnowMoreModal = (event) => {
    event.stopPropagation();
    setShowModal(true);
  };

  const hideKnowMoreModal = (event) => {
    event.stopPropagation();
    setShowModal(false);
  };

  const onFiltersApply = async (selectedHotspot) => {
    setIsLoading(true);
    try {
      setTransactionData([]);
      const hotspotData = currentStatusHotspots.find(
        (hotspot) => hotspot.label === selectedHotspot
      );
      const data = await getFunnelHotspotTransactions({
        ...filters,
        ...hotspotData,
        statusSelected
      });
      if (data.apiError) {
        setTransactionData([]);
        setIsLoading(false);
        return;
      }
      setTransactionData(data);
    } catch (error) {
      Sentry.captureException(error);
      setTransactionData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    onFiltersApply(activeHotspot);
    setShowModal(false);
  }, [activeHotspot]);

  useEffect(() => {
    setShowModal(false);
  }, []);

  return (
    <div className="hotspot__side_panel__body__container">
      <KnowMoreModal showModal={showModal} onHide={hideKnowMoreModal} />
      <Filter
        currentStatusHotspots={currentStatusHotspots}
        activeHotspot={activeHotspot}
        onFiltersApply={onFiltersApply}
      />
      <div className="hotspot__side_panel__body__info_container">
        <p>
          <InfoIcon className="hotspot__side_panel__body__info_icon" />
          Application status and flags within an application might have changed in the latest
          attempt
          <span onClick={displayKnowMoreModal} aria-hidden>
            Know more
          </span>
        </p>
      </div>
      <div className="hotspot__side_panel__transactions_table_container">
        <Table isLoading={isLoading} transactionData={transactionData} filters={filters} />
      </div>
    </div>
  );
}

Body.propTypes = {
  currentStatusHotspots: PropTypes.array.isRequired,
  activeHotspot: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  statusSelected: PropTypes.string.isRequired
};

export default Body;
