import React from 'react';

import { get } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer, getHiddenAndDisabledDrawerKeysFromPermissions } from 'storybook-ui-components';

import useGetUserPermissions from '../../../Permissions/hooks';
import navMapping from '../../../utils/navMapping';

import './Navbar.css';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { navKey, subNavKey } = get(navMapping, pathname, {});
  const { disallowedPermissions } = useGetUserPermissions();

  const { hiddenNavKeys, disabledNavKeys } =
    getHiddenAndDisabledDrawerKeysFromPermissions(disallowedPermissions);

  const drawerFunctions = {
    home: () => {
      window.location.replace(`${process.env.REACT_APP_AUDIT_PORTAL_BASE_URL}/home`);
    },
    applications: () => {
      window.location.replace(`${process.env.REACT_APP_AUDIT_PORTAL_BASE_URL}/applications`);
    },
    workflows: () => {
      window.location.replace(process.env.REACT_APP_WORKFLOW_APP_URL);
    },
    credentials: () => {
      window.location.replace(`${process.env.REACT_APP_AUDIT_PORTAL_BASE_URL}/credentials`);
    },
    usage: () => {
      window.location.replace(`${process.env.REACT_APP_AUDIT_PORTAL_BASE_URL}/usage`);
    },
    users: () => window.location.replace(`${process.env.REACT_APP_USERS_APP_URL}`),
    businessMetrics: () => {
      navigate('/');
    },
    stepwiseAnalytics: () => {
      navigate('/stepwise');
    },
    customReport: () => {
      navigate('/custom-report');
    }
  };

  return (
    <Drawer
      onClickFunctions={drawerFunctions}
      activeNav={navKey}
      activeSubNav={subNavKey}
      hiddenNavKeys={hiddenNavKeys}
      disabledNavKeys={disabledNavKeys}
    />
  );
}

export default Navbar;
