/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_RETENTION_DAYS } from '../constants';

const initState = {
  dataRetentionDays: DEFAULT_RETENTION_DAYS
};

export const configSlice = createSlice({
  name: 'config',
  initialState: initState,
  reducers: {
    updateDataRetentionDays: (state, action) => {
      const { dataRetentionDays } = action.payload;
      state.dataRetentionDays = dataRetentionDays;
    },
    reset: () => initState
  }
});

// Action creators are generated for each case reducer function
export const { reset, updateDataRetentionDays } = configSlice.actions;
export default configSlice.reducer;
