import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

function HDRow({ title, value, haveBottomSpacing }) {
  return (
    <Row className={`hover-details-data-row ${haveBottomSpacing && 'downspace'}`}>
      <Col xl={8} className="hover-details-data-col details-data-title">
        <p>{title}</p>
      </Col>
      <Col className="hover-details-data-col details-data-value">
        <p>{value}</p>
      </Col>
    </Row>
  );
}

HDRow.defaultProps = {
  haveBottomSpacing: false
};

HDRow.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  haveBottomSpacing: PropTypes.bool
};

export default HDRow;
