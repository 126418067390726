import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import './HoverTooltip.css';

function HoverTooltip({
  label,
  parentId,
  primaryValue,
  secondaryValue,
  primaryPercentage,
  secondaryPercentage
}) {
  useEffect(() => {
    if (!parentId) return;
    document.getElementById(parentId).addEventListener('mouseover', () => {
      document.getElementById(`${parentId}_comparison_tooltip`).classList.add('active');
    });

    document.getElementById(parentId).addEventListener('mouseout', () => {
      document.getElementById(`${parentId}_comparison_tooltip`).classList.remove('active');
    });
  }, [parentId]);

  return (
    <div id={`${parentId}_comparison_tooltip`} className="funnel-comparison-tooltip">
      <div>
        <h3>A</h3>
        <p>
          {label}: {primaryValue} ({primaryPercentage}%)
        </p>
      </div>
      <div>
        <h3>B</h3>
        <p>
          {label}: {secondaryValue} ({secondaryPercentage}%)
        </p>
      </div>
      <div className="funnel_comparison_tooltip_tip" />
    </div>
  );
}

HoverTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  primaryValue: PropTypes.number.isRequired,
  secondaryValue: PropTypes.number.isRequired,
  parentId: PropTypes.string.isRequired,
  primaryPercentage: PropTypes.number.isRequired,
  secondaryPercentage: PropTypes.number.isRequired
};

export default HoverTooltip;
