import * as Sentry from '@sentry/react';
import axios from 'axios';
import { detect } from 'detect-browser';

import getUserLocationDetails from '.';
import store from '../store';

const storeAnalyticsMetadata = async (
  eventName,
  currentScreen,
  table = 'dashboard',
  event = 'track_events'
) => {
  try {
    const browser = detect();
    const locDetails = await getUserLocationDetails();
    const { user } = store.getState();

    const properties = {
      clientId: user?.currentClientId,
      country: locDetails?.data?.country_name,
      ipAddress: locDetails?.data?.ip,
      device: navigator.platform,
      browser: browser?.name,
      browserVersion: browser?.version,
      eventName,
      userEmail: user?.userDetails?.email,
      timestamp: new Date().getTime(),
      product: 'dashboard',
      currentScreen,
      userRole: user?.userDetails?.role,
      hv_event_name: event,
      env: process.env.REACT_APP_ENV
    };

    const data = {
      project: table,
      properties
    };

    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/analytics/logToRedshift`,
      data,
      withCredentials: false
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

export default storeAnalyticsMetadata;
