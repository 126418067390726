import React from 'react';

import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const popover = (Body, popoverClass) => (
  <Popover className={popoverClass} id="popover-basic">
    {Body}
  </Popover>
);

function CommonPopover({ body, icon, trigger, popoverClass, iconClass }) {
  return (
    <OverlayTrigger trigger={trigger} placement="auto" overlay={popover(body, popoverClass)}>
      <img className={`info-icon ${iconClass}`} src={icon} alt="Info icon" />
    </OverlayTrigger>
  );
}

CommonPopover.defaultProps = {
  iconClass: ''
};

CommonPopover.propTypes = {
  body: PropTypes.any.isRequired,
  icon: PropTypes.any.isRequired,
  trigger: PropTypes.array.isRequired,
  popoverClass: PropTypes.string.isRequired,
  iconClass: PropTypes.string
};

export default CommonPopover;
