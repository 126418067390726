/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import './Toggle.css';

function Toggle({ label, initValue, onToggle }) {
  const [isActive, setIsActive] = useState(initValue);

  const toggleOnClick = () => {
    onToggle(!isActive);
    setIsActive(!isActive);
  };

  return (
    <div className="toggle_container">
      <p>{label}</p>
      <div onClick={toggleOnClick} className={`toggle round ${isActive ? 'active' : ''}`}>
        <span className={`slider round ${isActive ? 'active' : ''}`} aria-hidden />
      </div>
    </div>
  );
}

Toggle.defaultProps = {
  initValue: false
};

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  initValue: PropTypes.bool
};

export default Toggle;
