import {
  startOfDay,
  endOfDay,
  addDays,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
  differenceInDays,
  endOfToday,
  subDays,
  isBefore,
  isAfter
} from 'date-fns';

export const formatDate = (date) => {
  const formatedDate = new Date(date);
  const day = formatedDate.toLocaleString('en-US', { day: 'numeric' });
  const month = formatedDate.toLocaleString('en-US', { month: 'short' });
  const year = formatedDate.toLocaleString('en-US', { year: 'numeric' });
  return `${day} ${month} '${year.substring(2, 4)}`;
};

export const formatDateForApplicationTable = (date) => {
  if (!date) return '';
  const formatedDate = new Date(date);
  const time = formatedDate
    .toLocaleString('en-US', {
      hour12: true,
      hour: 'numeric',
      minute: 'numeric'
    })
    .toLowerCase();
  const day = formatedDate.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
  return `${time}, ${day}`;
};

const getCompleteDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const adjustStartDateForLeapYear = (endDate) => {
  let startDate = subDays(endDate, 365);
  const febTwentyNine = new Date(endDate.getFullYear(), 1, 29);
  if (
    (isAfter(febTwentyNine, startDate) && isBefore(febTwentyNine, endDate)) ||
    startOfDay(startDate).getTime() === febTwentyNine.getTime() ||
    startOfDay(endDate).getTime() === febTwentyNine.getTime()
  ) {
    startDate = subDays(endDate, 366);
  }
  return startDate;
};

export const formatDateForDropdown = (start, end) => {
  const startDate = startOfDay(new Date(start));
  const endDate = endOfDay(new Date(end));

  if (getCompleteDate(startDate) === getCompleteDate(endDate)) {
    if (getCompleteDate(startDate) === getCompleteDate(startOfDay(new Date()))) {
      return 'Today';
    }
    if (getCompleteDate(startDate) === getCompleteDate(startOfDay(addDays(new Date(), -1)))) {
      return 'Yesterday';
    }
    return `${formatDate(start)} - ${formatDate(end)}`;
  }

  if (
    getCompleteDate(startDate) === getCompleteDate(startOfWeek(addWeeks(new Date(), -1))) &&
    getCompleteDate(endDate) === getCompleteDate(endOfWeek(addWeeks(new Date(), -1)))
  ) {
    return 'Last Week';
  }

  if (
    getCompleteDate(startDate) === getCompleteDate(startOfWeek(new Date())) &&
    getCompleteDate(endDate) === getCompleteDate(endOfWeek(new Date()))
  ) {
    return 'This Week';
  }

  if (
    getCompleteDate(startDate) === getCompleteDate(startOfMonth(new Date())) &&
    getCompleteDate(endDate) === getCompleteDate(endOfMonth(new Date()))
  ) {
    return 'This Month';
  }

  if (
    getCompleteDate(startDate) === getCompleteDate(startOfMonth(addMonths(new Date(), -1))) &&
    getCompleteDate(endDate) === getCompleteDate(endOfMonth(addMonths(new Date(), -1)))
  ) {
    return 'Last Month';
  }

  if (
    (differenceInDays(endDate, startDate) === 365 ||
      differenceInDays(endDate, startDate) === 366) &&
    getCompleteDate(endDate) === getCompleteDate(endOfToday())
  ) {
    return 'This Year';
  }

  if (getCompleteDate(endDate) === getCompleteDate(endOfDay(new Date()))) {
    if (getCompleteDate(startDate) === getCompleteDate(startOfDay(addDays(new Date(), -7)))) {
      return 'Last 7 Days';
    }
    if (getCompleteDate(startDate) === getCompleteDate(startOfDay(addDays(new Date(), -14)))) {
      return 'Last 14 Days';
    }
    if (getCompleteDate(startDate) === getCompleteDate(startOfDay(addDays(new Date(), -30)))) {
      return 'Last 30 Days';
    }
  }

  return `${formatDate(start)} - ${formatDate(end)}`;
};
