import { get } from 'lodash';

import businessMetricsPagePermissions from './pages/businessMetrics';
import stepwisePagePermissions from './pages/stepwise';

const requiredPermissionsMapping = {
  ...businessMetricsPagePermissions,
  ...stepwisePagePermissions
};

const getPermission = (id) => get(requiredPermissionsMapping, id, {});

export default getPermission;
