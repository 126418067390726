const businessMetricsPageComponentPermissions = {
  requiredPermissions: {
    viewPermissions: ['businessMetrics.view'],
    interactPermissions: ['businessMetrics.interact']
  },
  redirect: () => {
    window.location.href = `${process.env.REACT_APP_AUDIT_PORTAL_BASE_URL}/home`;
  }
};

const businessMetricsPagePermissions = {
  businessMetrics: businessMetricsPageComponentPermissions
};

export default businessMetricsPagePermissions;
