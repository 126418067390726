import React from 'react';

import '../StepwiseFlow.css';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

import { END_STATES } from '../../../../../../constants';
import { getIconForSubType } from '../../utils';
import AddGoToEdges from '../GoToNodes/AddGoToEdges';
import HideGoToEdges from '../GoToNodes/HideGoToEdges';

import './OutputLabelNode.css';

function OutputLabelNode({ data }) {
  const getClassName = () => {
    if (END_STATES.includes(data.subType)) return data.subType;

    return '';
  };

  return (
    <div id={data?.nodeId} className={`label_node_output ${getClassName()}`}>
      <Handle type="target" position={Position.Top} />
      <div className="label_node_top">
        <div className="label_node_icon">{React.cloneElement(getIconForSubType(data.subType))}</div>
        <div className="label_node_heading">{data.label}</div>
        <AddGoToEdges data={data} idPath="gotoNodeId" />
        <HideGoToEdges data={data} />
      </div>
      <Handle type="source" position={Position.Bottom} id="out" />
    </div>
  );
}
OutputLabelNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default OutputLabelNode;
