import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';

import InfoIcon from '../../../../../../assets/icons/condition.svg';
import './ConditionNode.css';

function ConditionNode({ data }) {
  useEffect(() => {
    document.getElementById(data?.nodeId).addEventListener('mouseenter', () => {
      document.getElementById(`${data?.nodeId}_condition-hover-tooltip`)?.classList?.add('active');
    });

    document.getElementById(data?.nodeId).addEventListener('mouseleave', () => {
      document
        .getElementById(`${data?.nodeId}_condition-hover-tooltip`)
        ?.classList?.remove('active');
    });

    return () => {
      document.getElementById(data?.nodeId)?.removeEventListener('mouseenter', () => {
        document
          .getElementById(`${data?.nodeId}_condition-hover-tooltip`)
          ?.classList?.add('active');
      });

      document.getElementById(data?.nodeId)?.removeEventListener('mouseleave', () => {
        document
          .getElementById(`${data?.nodeId}_condition-hover-tooltip`)
          ?.classList?.remove('active');
      });
    };
  }, []);
  return (
    <div id={data?.nodeId} className="outer">
      <div id={`${data?.nodeId}_condition-hover-tooltip`} className="condition-hover-tooltip">
        <p>{data?.nodeId}</p>
        <div className="condition-hover-tooltip-tip" />
      </div>
      <Handle type="target" position={Position.Top} />
      <div className="diamond" />
      <div className="condition-icon">
        <img src={InfoIcon} alt="condition icon" aria-describedby="popover-basic" />
      </div>
      <Handle type="source" position={Position.Bottom} id="out" />
    </div>
  );
}

ConditionNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default ConditionNode;
