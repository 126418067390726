import store from '../store';

export const getStore = () => {
  return store.getState();
};

const getAPICallMetadataHeaders = () => {
  const { user } = getStore();
  return {
    'x-hv-user-email': user?.userDetails?.email,
    'x-hv-client-id': user?.currentClientId
  };
};

export default getAPICallMetadataHeaders;
