/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/react';
import { JSONPath } from 'jsonpath-plus';
import { get, keyBy } from 'lodash';

import { getPercentage, isNullOrUndefined } from './helpers';
import {
  AUTO_DECLINED,
  EDGE_TYPES,
  END_STATES,
  MANUAL_REVIEW,
  NODES_CLASSNAMES,
  NODE_TYPES,
  USER_CANCELLED,
  autoDeclinedEdgeColor,
  defaultMetrics,
  falseEdgeColor,
  manualReviewEdgeColor,
  trueEdgeColor,
  userCancelledEdgeColor
} from '../constants';

const formatModuleMetrics = (metrics) => {
  if (!metrics || !Object.keys(metrics).length) return defaultMetrics;
  return Object.keys(metrics).reduce((obj, key) => {
    obj[key] = metrics[key] || defaultMetrics[key];
    return obj;
  }, {});
};

const getDynamicFormNextStep = (dynamicFormModule) => {
  let nextSteps = JSONPath({
    path: '$..nextStep',
    json: dynamicFormModule
  });
  nextSteps = nextSteps.filter((item) => item !== '');

  return nextSteps;
};

const isChildModule = (modulesJson, nextStep) => {
  // TODO: Update the logic here post identifier is added to child modules
  if (modulesJson[nextStep]?.nextStep === 'dismissToParent') return true;
  return false;
};

const getGoToNodeId = (sourceNodeId, targetNodeId) => {
  return `${sourceNodeId}_${targetNodeId}__goto`;
};

const getGoToEdgeId = (sourceNodeId, targetNodeId) => {
  return `${sourceNodeId}_${targetNodeId}__goto_edge`;
};

const getGoToNodeName = (modulesJson, targetNodeId) => {
  return modulesJson[targetNodeId]?.name;
};

const processNextStep = (
  queue,
  currentModule,
  conditions,
  modulesJson,
  nextStep,
  conditionType,
  nodes,
  edgeColor,
  stepwiseResponse
) => {
  let nextStepId;
  let nextStepUsers;

  if (isChildModule(modulesJson, nextStep)) return {};

  if (conditions[nextStep]) {
    nextStepId = nextStep;
    const nextNodeMetrics = get(stepwiseResponse, nextStepId);

    queue.push({
      ...conditions[nextStep],
      id: nextStepId,
      type: NODE_TYPES.CONDITION,
      subType: NODE_TYPES.CONDITION,
      stepwiseNodeData: nextNodeMetrics,
      ...(conditionType !== undefined ? { conditionType } : {})
    });

    nextStepUsers = get(stepwiseResponse, [currentModule?.id, 'nextSteps', nextStepId]);
  } else if (modulesJson[nextStep]) {
    nextStepId = modulesJson[nextStep]?.id;
    const nextNodeMetrics = get(stepwiseResponse, nextStepId);

    queue.push({
      ...modulesJson[nextStep],
      id: nextStepId,
      stepwiseNodeData: nextNodeMetrics,
      ...(conditionType !== undefined ? { conditionType } : {})
    });

    nextStepUsers = get(stepwiseResponse, [currentModule?.id, 'nextSteps', nextStepId], 0);
  } else {
    nextStepId = `${nextStep}_${currentModule.id}`;

    let nodeType = 'approve';

    nextStepUsers = get(stepwiseResponse, [currentModule?.id, 'nextSteps', nextStep]);
    if (MANUAL_REVIEW.includes(nextStep)) {
      edgeColor = manualReviewEdgeColor;
      nodeType = 'manualReview';
    } else if (AUTO_DECLINED.includes(nextStep)) {
      edgeColor = autoDeclinedEdgeColor;
      nodeType = 'decline';
    } else if (USER_CANCELLED.includes(nextStep)) {
      edgeColor = userCancelledEdgeColor;
      nodeType = 'user_cancelled';
    }

    nodes.push({
      nodeType,
      id: nextStepId,
      ...(conditionType !== undefined ? { conditionType } : {}),
      stepwiseNodeData: {
        ...currentModule.stepwiseNodeData
      },
      data: {
        subType: currentModule.subType,
        nodeId: nextStepId
      }
    });
  }

  return {
    nextStepId,
    edgeColor,
    nextStepUsers: END_STATES.includes(nextStep) ? nextStepUsers || 0 : undefined
  };
};

const processConditionNode = (
  queue,
  currentModule,
  conditions,
  modulesJson,
  nodes,
  edges,
  stepwiseResponse,
  totalUsers,
  visitedNodes,
  hiddenEdges
) => {
  const {
    id: currentModuleId,
    subType,
    if_true: trueStep,
    if_false: falseStep,
    stepwiseNodeData
  } = currentModule;

  nodes.push({
    id: currentModuleId,
    nodeType: subType,
    stepwiseNodeData: {
      ...stepwiseNodeData
    },
    data: {
      subType,
      nodeId: currentModuleId
    }
  });

  const {
    // nextStepPercentage: falseStepPercentage,
    edgeColor: falseStepEdgeColor,
    nextStepId: falseStepId,
    nextStepUsers: falseStepUsers
  } = processNextStep(
    queue,
    currentModule,
    conditions,
    modulesJson,
    falseStep,
    false,
    nodes,
    falseEdgeColor,
    stepwiseResponse,
    totalUsers
  );

  const {
    // nextStepPercentage: trueStepPercentage,
    edgeColor: trueStepEdgeColor,
    nextStepId: trueStepId,
    nextStepUsers: trueStepUsers
  } = processNextStep(
    queue,
    currentModule,
    conditions,
    modulesJson,
    trueStep,
    true,
    nodes,
    trueEdgeColor,
    stepwiseResponse,
    totalUsers
  );

  if (visitedNodes[falseStepId]) {
    const gotoNodeId = getGoToNodeId(currentModuleId, falseStepId);
    const gotoNodeName = getGoToNodeName(modulesJson, falseStepId);
    const gotoEdgeId = getGoToEdgeId(currentModuleId, falseStepId);

    nodes.push({
      id: gotoNodeId,
      nodeType: NODE_TYPES.OUTPUT,
      name: gotoNodeName,
      data: {
        subType: NODE_TYPES.OUTPUT,
        gotoNodeId: falseStepId,
        nodeId: gotoNodeId,
        isGotoNode: true
      }
    });

    hiddenEdges.push({
      id: gotoEdgeId,
      source: gotoNodeId,
      target: falseStepId,
      type: EDGE_TYPES.MODULE,
      animated: false,
      ...(!isNullOrUndefined(falseStepUsers)
        ? { label: `${getPercentage(falseStepUsers, totalUsers)}%` }
        : {}),
      labelBgPadding: [6, 5, 3, 6],
      labelBgBorderRadius: '12px',
      labelBgStyle: { fill: falseStepEdgeColor.bg },
      labelStyle: {
        fill: falseStepEdgeColor.label,
        fontWeight: 500,
        fontFamily: 'Inter',
        fontSize: '12px',
        margin: '17px 11px 27px 68px',
        textAlign: 'center'
      },
      sources: [gotoNodeId],
      targets: [falseStepId]
    });
  }

  if (visitedNodes[trueStepId]) {
    const gotoNodeId = getGoToNodeId(currentModuleId, trueStepId);
    const gotoNodeName = getGoToNodeName(modulesJson, trueStepId);
    const gotoEdgeId = getGoToEdgeId(currentModuleId, trueStepId);

    nodes.push({
      id: gotoNodeId,
      nodeType: NODE_TYPES.OUTPUT,
      name: gotoNodeName,
      data: {
        subType: NODE_TYPES.OUTPUT,
        gotoNodeId: trueStepId,
        nodeId: gotoNodeId,
        isGotoNode: true
      }
    });

    hiddenEdges.push({
      id: gotoEdgeId,
      source: gotoNodeId,
      target: trueStepId,
      type: EDGE_TYPES.MODULE,
      animated: false,
      ...(!isNullOrUndefined(trueStepUsers)
        ? { label: `${getPercentage(trueStepUsers, totalUsers)}%` }
        : {}),
      labelBgPadding: [6, 5, 3, 6],
      labelBgBorderRadius: '12px',
      labelBgStyle: { fill: trueStepEdgeColor.bg },
      labelStyle: {
        fill: trueStepEdgeColor.label,
        fontWeight: 500,
        fontFamily: 'Inter',
        fontSize: '12px',
        margin: '17px 11px 27px 68px',
        textAlign: 'center'
      },
      sources: [gotoNodeId],
      targets: [trueStepId]
    });
  }

  edges.push(
    {
      id: `${currentModule.id}_${falseStepId}`,
      source: currentModule.id,
      target: visitedNodes[falseStepId] ? getGoToNodeId(currentModuleId, falseStepId) : falseStepId,
      type: EDGE_TYPES.CONDITION,
      animated: false,
      data: { branch: 'Current Branch' },
      ...(!isNullOrUndefined(falseStepUsers)
        ? { label: `${getPercentage(falseStepUsers, totalUsers)}%` }
        : {}),
      labelBgPadding: [6, 5, 3, 6],
      labelBgBorderRadius: '12px',
      labelBgStyle: { fill: falseStepEdgeColor.bg },
      labelStyle: {
        fill: falseStepEdgeColor.label,
        fontWeight: 500,
        fontFamily: 'Inter',
        fontSize: '12px',
        margin: '17px 11px 27px 68px',
        textAlign: 'center'
      }
    },
    {
      id: `${currentModule.id}_${trueStepId}`,
      source: currentModule.id,
      target: visitedNodes[trueStepId] ? getGoToNodeId(currentModuleId, trueStepId) : trueStepId,
      type: EDGE_TYPES.CONDITION,
      animated: false,
      data: { branch: 'Current Branch' },
      ...(!isNullOrUndefined(trueStepUsers)
        ? { label: `${getPercentage(trueStepUsers, totalUsers)}%` }
        : {}),
      labelBgPadding: [6, 5, 3, 6],
      labelBgBorderRadius: '12px',
      labelBgStyle: { fill: trueStepEdgeColor.bg },
      labelStyle: {
        fill: trueStepEdgeColor.label,
        fontWeight: 500,
        fontFamily: 'Inter',
        fontSize: '12px',
        margin: '17px 11px 27px 68px',
        textAlign: 'center'
      }
    }
  );
};

const processOtherNodes = (
  queue,
  currentModule,
  conditions,
  modulesJson,
  nodes,
  edges,
  stepwiseResponse,
  totalUsers,
  visitedNodes,
  hiddenEdges
) => {
  const { id: currentModuleId, subType, nextStep, conditionType, stepwiseNodeData } = currentModule;

  const { nextStepId, edgeColor } = processNextStep(
    queue,
    currentModule,
    conditions,
    modulesJson,
    nextStep,
    undefined,
    nodes,
    trueEdgeColor,
    stepwiseResponse,
    totalUsers
  );
  const dropOff = get(stepwiseResponse, [currentModule?.id, 'dropOff'], 0);

  nodes.push({
    id: currentModuleId,
    nodeType: subType,
    stepwiseNodeData: {
      ...stepwiseNodeData
    },
    name: currentModule.name,
    ...(conditionType !== undefined ? { conditionType } : {}),
    data: {
      subType,
      ...(dropOff !== undefined ? { dropOff } : {}),
      nodeId: currentModuleId
    }
  });

  if (visitedNodes[nextStepId]) {
    const gotoNodeId = getGoToNodeId(currentModuleId, nextStepId);
    const gotoNodeName = getGoToNodeName(modulesJson, nextStepId);
    const gotoEdgeId = getGoToEdgeId(currentModuleId, nextStepId);

    nodes.push({
      id: gotoNodeId,
      nodeType: NODE_TYPES.OUTPUT,
      name: gotoNodeName,
      data: {
        subType: NODE_TYPES.OUTPUT,
        gotoNodeId: nextStepId,
        nodeId: gotoNodeId,
        isGotoNode: true
      }
    });

    hiddenEdges.push({
      id: gotoEdgeId,
      source: gotoNodeId,
      target: nextStepId,
      type: EDGE_TYPES.MODULE,
      animated: false,
      // label: `${getLabel(nextStepPercentage)}%`,
      // labelBgPadding: [6, 5, 3, 6],
      // labelBgBorderRadius: '12px',
      // labelBgStyle: { fill: edgeColor.bg },
      // labelStyle: {
      //   fill: edgeColor.label,
      //   fontWeight: 500,
      //   fontFamily: 'Inter',
      //   fontSize: '12px',
      //   margin: '17px 11px 27px 68px',
      //   textAlign: 'center'
      // },
      sources: [gotoNodeId],
      targets: [nextStepId]
    });
  }

  if (isChildModule(modulesJson, nextStep)) return;

  edges.push({
    id: `${currentModule.id}_${nextStepId}`,
    source: currentModule.id,
    target: visitedNodes[nextStepId] ? getGoToNodeId(currentModuleId, nextStepId) : nextStepId,
    type: EDGE_TYPES.MODULE,
    animated: false,
    ...(edges.length === 0 ? { label: '100%' } : {}),
    labelBgPadding: [6, 5, 3, 6],
    labelBgBorderRadius: '12px',
    labelBgStyle: { fill: edgeColor.bg },
    labelStyle: {
      fill: edgeColor.label,
      fontWeight: 500,
      fontFamily: 'Inter',
      fontSize: '12px',
      margin: '17px 11px 27px 68px',
      textAlign: 'center'
    }
  });

  // Not sure why this is being used, was used in the earlier code base and keeping this for the time being for HDFC demo so nothing breaks
  // This might be redundant and we might need to remove this
  if (conditions[nextStep]) {
    edges[edges.length - 1] = {
      ...edges[edges.length - 1],
      fromCondition: true
      // labelBgPadding: [6, 5, 3, 6],
      // labelBgBorderRadius: '12px',
      // labelBgStyle: { fill: '#f2fcf9' },
      // labelStyle: {
      //   fill: '#064',
      //   fontWeight: 500,
      //   fontFamily: 'Inter',
      //   fontSize: '12px',
      //   margin: '17px 11px 27px 68px',
      //   textAlign: 'center'
      // }
    };
  }
};

const processDynamicFormNode = (
  queue,
  currentModule,
  conditions,
  modulesJson,
  nodes,
  edges,
  stepwiseResponse,
  totalUsers,
  visitedNodes,
  hiddenEdges
) => {
  const nextSteps = getDynamicFormNextStep(currentModule);

  nextSteps.forEach((nextStep) => {
    const dynamicFormModule = { ...currentModule, nextStep };
    processOtherNodes(
      queue,
      dynamicFormModule,
      conditions,
      modulesJson,
      nodes,
      edges,
      stepwiseResponse,
      totalUsers,
      visitedNodes,
      hiddenEdges
    );
  });
};

export const getNodesAndEdgesForWorkflow = (workflow, stepwiseResponse) => {
  const { modules, conditions } = workflow;
  const modulesJson = keyBy(modules, 'id');

  let nodes = [];
  const edges = [];
  const hiddenEdges = [];
  const visitedNodes = {};

  if (!modules.length) return { nodes, edges };

  const queue = [
    {
      id: 'start',
      subType: 'start',
      type: 'output',
      properties: {},
      nextStep: modules[0].id,
      stepwiseNodeData: formatModuleMetrics(get(stepwiseResponse, modules[0].id)),
      x: 0,
      level: 0
    }
  ];

  const totalUsers = get(stepwiseResponse, [modules[0]?.id, 'totalTransactionsCount'], 0);

  while (queue.length > 0) {
    const currentModule = queue.shift();
    const { id, type } = currentModule;

    if (visitedNodes[id] === true) continue;
    visitedNodes[id] = true;

    if (type === NODE_TYPES.CONDITION) {
      processConditionNode(
        queue,
        currentModule,
        conditions,
        modulesJson,
        nodes,
        edges,
        stepwiseResponse,
        totalUsers,
        visitedNodes,
        hiddenEdges
      );
    } else if (type === NODE_TYPES.DYNAMIC_FORM || type === NODE_TYPES.DYNAMIC_FORM_V2) {
      processDynamicFormNode(
        queue,
        currentModule,
        conditions,
        modulesJson,
        nodes,
        edges,
        stepwiseResponse,
        totalUsers,
        visitedNodes,
        hiddenEdges
      );
    } else {
      processOtherNodes(
        queue,
        currentModule,
        conditions,
        modulesJson,
        nodes,
        edges,
        stepwiseResponse,
        totalUsers,
        visitedNodes,
        hiddenEdges
      );
    }
  }

  edges.forEach((edge) => {
    if (!edge.type) edge.type = 'step';
    edge.style = {};
    edge.style.stroke = 'rgba(5, 5, 82, 0.2)';
  });

  const gotoTargets = hiddenEdges.map((hiddenEdge) => hiddenEdge?.target);
  nodes = nodes.reduce((accumulator, node) => {
    let currentNodeData = { ...node };
    if (gotoTargets.includes(node?.id)) {
      currentNodeData = {
        ...currentNodeData,
        data: {
          isGotoTarget: true,
          ...currentNodeData?.data
        }
      };
    }

    accumulator.push(currentNodeData);
    return accumulator;
  }, []);

  return { nodes, edges, hiddenEdges };
};

const getNodeWithUIPropertiesBasedOnDisplayType = (node, displayType, uiConfig) => {
  switch (displayType) {
    case NODE_TYPES.INPUT:
      return {
        ...node,
        id: 'start',
        type: 'output',
        className: 'nodes__module_output',
        data: {
          ...node.data,
          label: node.name || uiConfig.node.heading,
          subType: 'start'
        }
      };
    case NODE_TYPES.CONDITION:
      return {
        ...node,
        type: displayType,
        className: 'condition',
        data: {
          ...node.data,
          label: 'condition'
        }
      };
    case NODE_TYPES.OUTPUT:
      return {
        ...node,
        type: displayType,
        className: 'nodes__module_output',
        data: {
          ...node.data,
          label: node.name || uiConfig.node.heading,
          subType: node.nodeType
        }
      };
    case NODE_TYPES.MANUAL_REVIEW:
      return {
        ...node,
        type: displayType,
        className: 'nodes__module_output',
        data: {
          ...node.data,
          label: node.name || uiConfig.node.heading,
          subType: node.nodeType
        }
      };
    default:
      return {
        ...node,
        type: displayType,
        className: 'nodes__module',
        data: {
          label: `${node.name || uiConfig.node.heading}`,
          stepwiseNodeData: node.stepwiseNodeData,
          ...node.data
        }
      };
  }
};

export const addUIPropertiesToNodes = (currentWorkflowNodes, workflowModules) => {
  return currentWorkflowNodes.map((currentNode) => {
    try {
      // Temp condition to display a box component for webview instead of just label component
      // TODO: Remove this after adding uiConfigs
      const nodeType = currentNode.nodeType.includes('webview') ? 'form' : currentNode.nodeType;
      const properties = workflowModules[nodeType];
      if (nodeType === '' || !properties) {
        if (nodeType === NODE_TYPES.USER_CANCELLED) {
          return {
            ...currentNode,
            type: NODE_TYPES.OUTPUT,
            className: NODES_CLASSNAMES.OUTPUT,
            data: {
              ...currentNode.data,
              label: 'User Cancelled',
              subType: currentNode.nodeType
            }
          };
        }

        if (!currentNode?.data?.isGotoNode) {
          return {
            ...currentNode,
            type: NODE_TYPES.DEFAULT,
            className: NODES_CLASSNAMES.DEFAULT,
            data: {
              ...currentNode.data,
              stepwiseNodeData: currentNode?.stepwiseNodeData,
              label: currentNode.name || 'Dynamic Form'
            }
          };
        }

        return {
          ...currentNode,
          type: NODE_TYPES.OUTPUT,
          className: NODES_CLASSNAMES.OUTPUT,
          data: {
            ...currentNode.data,
            label: 'Go To Node'
          }
        };
      }
      const { nodeDisplayType, uiConfig } = properties;

      return getNodeWithUIPropertiesBasedOnDisplayType(currentNode, nodeDisplayType, uiConfig);
    } catch (error) {
      Sentry.captureException(error);
      throw new Error(`Error loading config: module ${currentNode.nodeType} does not exist`);
    }
  });
};

export const renderGotoEdges = (hiddenEdges, nodes) => {
  const nodesById = keyBy(nodes, 'id');
  const processedHiddenEdges = hiddenEdges.reduce((accumulator, hiddenEdge) => {
    accumulator.push({
      ...hiddenEdge,
      sections: [
        {
          incomingShape: hiddenEdge?.source,
          outgoingShape: hiddenEdge?.target,
          id: `${hiddenEdge?.source}_${hiddenEdge?.target}_s0`,
          startPoint: nodesById[hiddenEdge?.source]?.position,
          endPoint: nodesById[hiddenEdge?.target]?.position,
          style: {
            stroke: 'rgba(5, 5, 82, 0.2)'
          }
        }
      ]
    });
    return accumulator;
  }, []);

  return processedHiddenEdges;
};

export const displayGotoEdgesButton = (data, activeGoto) => {
  return (
    (data?.isGotoNode || data.isGotoTarget) &&
    activeGoto !== data.nodeId &&
    activeGoto !== data.gotoNodeId
  );
};

export const hideGotoEdgesButton = (data, activeGoto) => {
  return (
    (data?.isGotoNode || data.isGotoTarget) &&
    (activeGoto === data?.nodeId || activeGoto === data.gotoNodeId)
  );
};

export const checkMissingModuleName = ({ modules }) => {
  return modules.some((module) => !!module.name);
};
