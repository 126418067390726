import React from 'react';

import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import PlusIcon from '../../../../../../../assets/icons/add.png';
import { updateActiveGoto } from '../../../../../../../reducers/stepwise';
import { displayGotoEdgesButton } from '../../../../../../../utils/stepwise';

function AddGoToEdges({ data, idPath }) {
  const dispatch = useDispatch();
  const activeGoto = useSelector((state) => state.stepwise.activeGoto);

  if (displayGotoEdgesButton(data, activeGoto)) {
    return (
      <img
        aria-hidden
        onClick={() => dispatch(updateActiveGoto({ activeGoto: get(data, idPath) }))}
        className="show_hidden_edges_icon"
        src={PlusIcon}
        alt="Show hidden edges"
      />
    );
  }

  return <div />;
}

AddGoToEdges.propTypes = {
  data: PropTypes.object.isRequired,
  idPath: PropTypes.string.isRequired
};

export default AddGoToEdges;
