import React, { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import './stepwise.css';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PermissionWrapper } from 'storybook-ui-components';

import { getStepwiseAnalytics } from '../../api/analytics';
import { getWorkflowV2 } from '../../api/workflow';
import StepwiseAnalytics from '../../Components/PageComponents/Home/StepwiseAnalytics';
import AnalyticsFilters from '../../Components/Shared/Filters';
import useWorkflowHooks from '../../Components/useWorkflowHooks';
import { WARNING_BANNER_IDS, WARNING_BANNER_MESSAGES } from '../../constants';
import {
  ANALYTICS_TRACKING_EVENT_NAMES,
  ANALYTICS_TRACKING_SCREEN_NAMES
} from '../../constants/tracking';
import CustomConnect from '../../containers/HOC/CustomConnect';
import useGetUserPermissions from '../../Permissions/hooks';
import getPermission from '../../Permissions/mapping';
import {
  reset,
  updateHotspots,
  updateRenderGraph,
  updateStepwiseGraph
} from '../../reducers/stepwise';
import { getAnalyticsLanding, isAPIError, returnDefaultDateRange } from '../../utils/helpers';
import {
  addUIPropertiesToNodes,
  checkMissingModuleName,
  getNodesAndEdgesForWorkflow
} from '../../utils/stepwise';
import processStepwiseHotspots from '../../utils/stepwise/hotspots';
import { preprocessStepwiseData, preprocessWorkflow } from '../../utils/stepwise/superModules';
import storeAnalyticsMetadata from '../../utils/storeAnalyticsUsageMetadata';

function Stepwise() {
  const dispatch = useDispatch();

  const { fetchModules } = useWorkflowHooks();
  const [firstLoad, setFirstLoad] = useState(true);
  const [loadingStepwise, setLoadingStepwise] = useState(false);
  const [loadingHotspots, setLoadingHotspots] = useState(false);
  const [showErrorContent, setShowErrorContent] = useState(false);
  const [debugMode, setDebugMode] = useState(false);
  const [lastModified, setLastModified] = useState('');
  const workflowModules = useSelector((state) => state.workflow.modules);
  const [defaultFilters, setDefaultFilters] = useState({
    dateRange: returnDefaultDateRange(),
    clientId: useSelector((state) => state.user.currentClientId),
    useCase: useSelector((state) => state.user.currentUseCase),
    environment: useSelector((state) => state.user.currentEnvironment),
    appId: '',
    workflowId: ''
  });

  const resetStepwiseData = () => {
    dispatch(reset());
    if (toast.isActive(WARNING_BANNER_IDS.MISSING_MODULE_NAME)) {
      toast.dismiss(WARNING_BANNER_IDS.MISSING_MODULE_NAME);
    }
  };

  const checkWorkflowModifed = (lastModified) => {
    if (!lastModified) return;

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const momentStartDate = moment(defaultFilters?.dateRange?.startDate);
    const momentLastModified = moment(lastModified);

    if (momentLastModified.isAfter(momentStartDate)) {
      toast.info(
        'Your workflow was modified within the time range selected by you, results would contain metrics for an older workflow version',
        {
          toastId: 'workflow_modification_toast',
          autoClose: false
        }
      );
    }

    setLastModified(momentTz.tz(lastModified, tz).format('DD-MM-YYYY hh:mm A'));
  };

  const fetchStepwiseData = async () => {
    try {
      setLoadingStepwise(true);
      setLoadingHotspots(true);
      setShowErrorContent(false);
      if (!defaultFilters.appId || !defaultFilters.workflowId || !defaultFilters.dateRange) return;

      const { workflow, lastModified, error, errorMessage } = await getWorkflowV2({
        appId: defaultFilters.appId,
        workflowId: defaultFilters.workflowId,
        ...(defaultFilters?.s3VersionId ? { s3VersionId: defaultFilters?.s3VersionId } : {})
      });

      if (error || !workflow) {
        toast.error(errorMessage || 'Error fetching workflow', {
          toastId: 'no_workflow_found'
        });
        setLastModified('');
        dispatch(updateRenderGraph({ renderGraph: false }));
        setShowErrorContent(true);
        return;
      }

      const data = await getStepwiseAnalytics(
        defaultFilters.appId,
        defaultFilters.workflowId,
        defaultFilters.dateRange
      );

      if (isAPIError(data)) {
        setShowErrorContent(true);
        return;
      }

      const missingModuleNames = checkMissingModuleName(workflow);

      if (missingModuleNames) {
        toast.warning(WARNING_BANNER_MESSAGES.MISSING_MODULE_NAME, {
          toastId: WARNING_BANNER_IDS.MISSING_MODULE_NAME
        });
      }

      dispatch(updateRenderGraph({ renderGraph: !!Object.keys(data).length }));

      checkWorkflowModifed(lastModified);
      const preprocessedWorkflow = preprocessWorkflow(workflow);
      const { preprocessedStepwiseData } = preprocessStepwiseData(data, workflow);
      const converted = getNodesAndEdgesForWorkflow(preprocessedWorkflow, preprocessedStepwiseData);
      const uiAddedNodes = addUIPropertiesToNodes(converted.nodes, workflowModules);
      dispatch(
        updateStepwiseGraph({
          nodes: uiAddedNodes,
          edges: converted?.edges,
          hiddenEdges: converted?.hiddenEdges
        })
      );
      setLoadingStepwise(false);

      const { dropOffsData, highTimeData, highRetakesData, backPressedHotspotData } =
        processStepwiseHotspots({
          stepwiseMetrics: preprocessedStepwiseData,
          workflow: preprocessedWorkflow
        });

      dispatch(
        updateHotspots({
          dropOffs: dropOffsData,
          highTime: highTimeData,
          highRetakes: highRetakesData,
          backPressed: backPressedHotspotData
        })
      );

      setLoadingHotspots(false);
    } catch (error) {
      Sentry.captureException(error);
      setLoadingHotspots(false);
      setLoadingStepwise(false);
    }
  };

  const onFiltersApply = async () => {
    resetStepwiseData();
    setFirstLoad(false);
    fetchStepwiseData();
  };

  const getMetadata = async () => {
    await fetchModules();
  };

  useEffect(() => {
    const debugModeParam = new URLSearchParams(document.location.search).get('debugMode');
    dispatch(reset());
    setDebugMode(debugModeParam === 'true');
    storeAnalyticsMetadata(
      ANALYTICS_TRACKING_EVENT_NAMES.STEPWISE_PAGE_LOAD,
      ANALYTICS_TRACKING_SCREEN_NAMES.STEPWISE_ANALYTICS
    );
    getMetadata();
  }, []);

  return (
    <div className="home-parent-div">
      <ToastContainer />
      <div role="presentation" className="home-content-div">
        <div className="stepwise-header-container">
          <h3>Stepwise Analytics</h3>
          <AnalyticsFilters
            isLoading={loadingHotspots || loadingStepwise}
            filters={defaultFilters}
            updateFilters={setDefaultFilters}
            debugMode={debugMode}
            dispatchUpdates={false}
            onFiltersApply={onFiltersApply}
          />
        </div>
        {firstLoad || showErrorContent ? (
          <div className="stepwise-landing-div">
            {getAnalyticsLanding(showErrorContent, fetchStepwiseData)}
          </div>
        ) : (
          <div className="stepwise-content-container">
            <StepwiseAnalytics
              loadingStepwise={loadingStepwise}
              loadingHotspots={loadingHotspots}
              filters={defaultFilters}
              lastModified={lastModified}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PermissionWrapper(
  CustomConnect(Stepwise, { mapHooksToActions: {} }),
  useGetUserPermissions,
  getPermission('stepwiseAnalytics')
);
