import buildImage from '../../assets/icons/build.svg';
import errorImage from '../../assets/icons/error.svg';
import { CUSTOM_REPORT_ERROR_CODES } from '../../constants';

export const transformLinkDataToSelectOption = ({ title, link }) => ({
  label: title,
  value: link,
  link,
  title
});

export const getErrorDisplayDataFromErrorCode = (errorCode) => {
  if (errorCode === CUSTOM_REPORT_ERROR_CODES.metabase.NO_STATIC_EMBEDDING_LINKS_FOUND) {
    return {
      heading: 'Build Tailor-made Reports',
      subHeading: 'To start creating your custom reports please contact HyperVerge',
      image: buildImage
    };
  }

  return {
    heading: 'Unknown Error',
    subHeading: 'Please retry at a later time',
    image: errorImage
  };
};
