import React from 'react';

import PropTypes from 'prop-types';

import { getErrorDisplayDataFromErrorCode } from '../../../utils/customReport';

function ErrorDisplay({ errorCode }) {
  const { heading, subHeading, image } = getErrorDisplayDataFromErrorCode(errorCode);
  return (
    <div className="custom-report-body-container error">
      <div className="custom-report-body-error-container">
        <img src={image} alt="Landing" />
        <h5>{heading}</h5>
        <p>{subHeading}</p>
      </div>
    </div>
  );
}

ErrorDisplay.propTypes = {
  errorCode: PropTypes.string.isRequired
};

export default ErrorDisplay;
