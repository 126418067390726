import React, { useEffect, useRef, useState } from 'react';

import './Dropdown.css';
import PropTypes from 'prop-types';

function Dropdown({ options, value, onChange, name, label }) {
  const dropdownRef = useRef();
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    window.addEventListener('click', (event) => {
      if (event.target !== dropdownRef?.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    });

    return window.removeEventListener('click', (event) => {
      if (event.target !== dropdownRef?.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    });
  }, []);

  const handleClick = () => {
    setShowOptions((prev) => !prev);
  };

  return (
    <div ref={dropdownRef} aria-hidden onClick={handleClick} className="dropdown__container">
      <div className="dropdown__label">
        <p>
          {label} &quot;<span>{value}</span>&quot;
        </p>
      </div>
      {showOptions && (
        <div
          className={`dropdown__options ${
            showOptions ? 'dropdown__option__active' : 'dropdown__options__hidden'
          }`}>
          {options.map((opt) => {
            return (
              <div
                aria-hidden
                onClick={() => {
                  onChange(name, opt);
                }}
                key={opt}>
                <p>{opt}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default Dropdown;
