const navMapping = {
  '/': {
    navKey: 'analytics',
    subNavKey: 'analytics'
  },
  '/stepwise': {
    navKey: 'analytics',
    subNavKey: 'stepwiseAnalytics'
  },
  '/custom-report': {
    navKey: 'analytics',
    subNavKey: 'customReport'
  }
};

export default navMapping;
