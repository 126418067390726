const stepwisePageComponentPermissions = {
  requiredPermissions: {
    viewPermissions: ['stepwiseAnalytics.view'],
    interactPermissions: ['stepwiseAnalytics.interact']
  },
  redirect: () => {
    window.location.href = `${process.env.REACT_APP_AUDIT_PORTAL_BASE_URL}/home`;
  }
};

const stepwisePagePermissions = {
  stepwiseAnalytics: stepwisePageComponentPermissions
};

export default stepwisePagePermissions;
