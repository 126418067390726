import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/user`,
  withCredentials: true
});

export const authenticateUserAPI = () => axiosRequest.get('/authenticate');

export const getUserAPI = () => axiosRequest.get('');

export const logoutAPI = () => axiosRequest.get('/logout');
