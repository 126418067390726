export const ANALYTICS_TRACKING_EVENT_NAMES = {
  STEPWISE_PAGE_LOAD: 'Stepwise Analytics Page Load',
  BUSINESS_METRICS_PAGE_LOAD: 'Business Metrics Page Load',
  BUSINESS_METRICS_COMPARISONS_PAGE_LOAD: 'Business Metrics Comparison Page Load'
};

export const ANALYTICS_TRACKING_SCREEN_NAMES = {
  STEPWISE_ANALYTICS: 'Stepwise Analytics',
  BUSINESS_METRICS: 'Business Metrics',
  BUSINESS_METRICS_COMPARSIONS: 'Business Metrics Comparison'
};
