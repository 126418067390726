import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import ELK from 'elkjs';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  MarkerType,
  ReactFlowProvider,
  ControlButton
} from 'reactflow';

import './StepwiseFlow.css';
import ConditionNode from './CustomNodes/ConditionNode';
import LabelNode from './CustomNodes/LabelNode';
import OutputLabelNode from './CustomNodes/OutputLabelNode';
import StartIcon from '../../../../../assets/icons/starticon.png';
import { renderGotoEdges } from '../../../../../utils/stepwise';
import FullScreenModal from '../../../Stepwise/FullScreenModal';

const nodeWidth = 150;
const conditionWidth = 200;
const nodeHeight = 40;
const nodeOutputWidth = 140;

const RELATIVE_X = 0.4;
const RELATIVE_Y = 0.1;

const getLayoutedElements = async (nodes, edges) => {
  const elk = new ELK({});
  const newNodes = nodes.map((node) => ({ ...node, width: nodeWidth, height: nodeHeight }));
  const newEdges = edges.map((node) => ({
    ...node,
    sources: [node.source],
    targets: [node.target],
    type: 'smoothstep',
    markerEnd: {
      type: MarkerType.ArrowClosed
    }
  }));
  const graph = {
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'DOWN',
      'elk.layered.spacing.nodeNodeBetweenLayers': '120',
      'elk.layered.spacing.edgeNodeBetweenLayers': '100',
      'elk.layered.spacing.edgeEdgeBetweenLayers': '200',
      'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
      'elk.radial.rotation.computeAdditionalWedgeSpace': 'true',
      'elk.spacing.nodeNode': '200',
      'elk.spacing.edgeEdge': '200',
      'elk.spacing.edgeNode': '500',
      'elk.spacing.labelLabel': '100',
      'elk.spacing.labelNode': '100',
      'elk.layered.unnecessaryBendpoints': true
    },
    children: newNodes,
    edges: newEdges
  };
  const layout = await elk.layout(graph);
  const layoutNodes = layout.children.map((node) => {
    let nodeX = node.x;
    let nodeY = node.y;
    // Centering conditions
    if (node.nodeType === 'condition') {
      nodeX += nodeWidth / 2 - conditionWidth / 2;
    }
    if (node.type === 'output') {
      nodeX += (nodeWidth - nodeOutputWidth) / 2;
    }
    if (node.id === 'start') {
      nodeY -= -30;
    }
    if (node.conditionType !== undefined) {
      nodeY -= 10;
    }
    return { ...node, position: { x: nodeX, y: nodeY } };
  });
  return { nodes: layoutNodes, edges: layout.edges };
};

function ELKLayout() {
  const initialNodes = useSelector((state) => state.stepwise.nodes);
  const initialEdges = useSelector((state) => state.stepwise.edges);
  const hiddenEdges = useSelector((state) => state.stepwise.hiddenEdges);

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [visibleEdges, setVisibleEdges] = useState([]);
  const [preprocessedHiddenEdges, setPreprocessedHiddenEdges] = useState([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const ref = useRef(null);
  const activeGoto = useSelector((state) => {
    return state?.stepwise?.activeGoto;
  });

  const activeHotspot = useSelector((state) => {
    return state?.stepwise?.activeHotspot;
  });

  useLayoutEffect(() => {
    if (ref) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, []);

  const nodeTypes = { condition: ConditionNode, default: LabelNode, output: OutputLabelNode };

  useEffect(() => {
    async function asyncFunc() {
      const { nodes: layoutedNodes, edges: layoutedEdges } = await getLayoutedElements(
        initialNodes,
        initialEdges
      );
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
      setVisibleEdges(layoutedEdges);
      setPreprocessedHiddenEdges(renderGotoEdges(hiddenEdges, layoutedNodes));
    }
    asyncFunc();
  }, [initialNodes, initialEdges]);

  const getStartPosition = (startNode, pannelWidth, pannelHeight) => {
    const { x: startX, y: startY } = startNode || {};
    if (startNode) {
      const yFactor = startY > 0 ? -1 * startY : startY;
      return {
        x: pannelWidth * RELATIVE_X - 1 * startX,
        y: pannelHeight * RELATIVE_Y + yFactor,
        zoom: 1
      };
    }
    return { x: 0, y: 0, zoom: 1 };
  };

  const getNodePosition = (position, pannelWidth, pannelHeight) => {
    const { x: startX, y: startY } = position || {};
    if (startX && startY) {
      const yFactor = startY > 0 ? -1 * startY : startY;
      return {
        x: pannelWidth * RELATIVE_X - 1 * startX,
        y: pannelHeight * RELATIVE_Y + yFactor,
        zoom: 1
      };
    }
    return { x: 0, y: 0, zoom: 1 };
  };

  const initialiseViewport = (event) => {
    event?.stopPropagation();
    if (reactFlowInstance) {
      const viewport = getStartPosition(nodes[0], width, height);
      reactFlowInstance.setViewport(viewport, { duration: 1000 });
    }
  };

  const viewActiveHotspot = () => {
    if (!reactFlowInstance || !activeHotspot) return;
    const { positionAbsolute } = reactFlowInstance?.getNode(activeHotspot) || {};
    if (!positionAbsolute) return;
    const targetPosition = getNodePosition(positionAbsolute, width, height);
    reactFlowInstance.setViewport(targetPosition, { duration: 1000 });
  };

  const translateExtent = useMemo(
    () =>
      nodes.reduce(
        ([[left, top], [right, bottom]], { position }) => [
          [
            Math.min(left, (position ?? { x: Infinity }).x - width / 2),
            Math.min(top, (position ?? { y: Infinity }).y - height / 2)
          ],
          [
            Math.max(right, (position ?? { x: -Infinity }).x + width),
            Math.max(bottom, (position ?? { y: -Infinity }).y + height * 5)
          ]
        ],
        [
          [Infinity, Infinity],
          [-Infinity, -Infinity]
        ]
      ),
    [nodes, height, width]
  );

  const showFullScreen = (event) => {
    event.stopPropagation();
    setShowFullScreenModal(true);
  };

  const hideFullScreen = (event) => {
    event.stopPropagation();
    setShowFullScreenModal(false);
  };

  useEffect(() => {
    if (activeGoto) {
      const currentGotoEdges = preprocessedHiddenEdges.filter((edge) => edge.target === activeGoto);
      setEdges([...visibleEdges, ...currentGotoEdges]);
    } else setEdges([...visibleEdges]);
  }, [activeGoto]);

  useEffect(() => {
    viewActiveHotspot();
  }, [activeHotspot]);

  return (
    <>
      <div ref={ref} className="layout">
        {!showFullScreenModal && nodes.length > 0 && (
          <ReactFlow
            onInit={(instance) => setReactFlowInstance(instance)}
            defaultViewport={getStartPosition(nodes[0], width, height)}
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            zoomOnScroll={false}
            panOnScroll
            translateExtent={translateExtent}
            fitViewOptions={{ includeHiddenNodes: true }}
            maxZoom={1}
            minZoom={0.1}>
            <Controls showInteractive={false}>
              <ControlButton type="button" title="Go to Start" onClick={initialiseViewport}>
                <img className="stepwise-start-icon" src={StartIcon} alt="Start Icon" />
              </ControlButton>
              <ControlButton type="button" title="View Full Screen" onClick={showFullScreen}>
                <BsArrowsFullscreen />
              </ControlButton>
            </Controls>
          </ReactFlow>
        )}
      </div>
      <FullScreenModal
        showFullScreenModal={showFullScreenModal}
        hideFullScreen={hideFullScreen}
        nodes={[...nodes]}
        edges={[...edges]}
        nodeTypes={nodeTypes}
        getStartPosition={getStartPosition}
      />
    </>
  );
}

function ELKLayoutProvider() {
  return (
    <ReactFlowProvider>
      <ELKLayout />
    </ReactFlowProvider>
  );
}

export default ELKLayoutProvider;
