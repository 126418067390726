import React from 'react';

import PropTypes from 'prop-types';

import Filters from './Filters';

function Header({ errorCode, customMetabaseLinks, selectedLinkData, setSelectedLinkData }) {
  const shouldShowFilters = !errorCode;

  return (
    <div className="custom-report-header-container">
      <h3>Custom Report:</h3>
      {shouldShowFilters ? (
        <Filters
          customMetabaseLinks={customMetabaseLinks}
          selectedLinkData={selectedLinkData}
          setSelectedLinkData={setSelectedLinkData}
        />
      ) : (
        ''
      )}
    </div>
  );
}

Header.propTypes = {
  errorCode: PropTypes.string.isRequired,
  customMetabaseLinks: PropTypes.array.isRequired,
  selectedLinkData: PropTypes.object.isRequired,
  setSelectedLinkData: PropTypes.func.isRequired
};

export default Header;
