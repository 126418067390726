import React, { useEffect, useState } from 'react';

import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';
import { Spinner } from 'clm-components';
import PropTypes from 'prop-types';
import 'react-date-range/dist/theme/default.css';
import { ToastContainer } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { authenticateUser, getUser } from './actions/user';
import CustomConnect from './containers/HOC/CustomConnect';
import Layout from './Layout';
import BusinessMetrics from './Pages/BusinessMetrics';
import CustomReport from './Pages/CustomReport';
import NotFound from './Pages/NotFound';
import Stepwise from './Pages/Stepwise';

function App({ authenticateUser, getUser }) {
  const [isLoading, setIsLoading] = useState(true);

  const initializeApp = async () => {
    setIsLoading(true);
    await authenticateUser();
    await getUser();
    setIsLoading(false);
  };

  useEffect(() => {
    initializeApp();
  }, []);

  if (isLoading) {
    return (
      <div className="App loader">
        <Spinner size="meduim" />
      </div>
    );
  }

  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({ routingInstrumentation: Sentry.reactRouterV6Instrumentation() })
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ToastContainer />
        <Router>
          <SentryRoutes>
            <Route path="/" element={<Layout />}>
              <Route index element={<BusinessMetrics />} />
            </Route>
            <Route path="/stepwise" element={<Layout />}>
              <Route index element={<Stepwise />} />
            </Route>
            <Route path="/custom-report" element={<Layout />}>
              <Route index element={<CustomReport />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </SentryRoutes>
        </Router>
      </LocalizationProvider>
    </div>
  );
}

App.propTypes = {
  authenticateUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired
};

const mapHooksToActions = {
  authenticateUser,
  getUser
};

export default CustomConnect(App, { mapHooksToActions });
