import React, { useEffect, useState } from 'react';

import { Spinner } from 'clm-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { TABLE_COLUMNS, generateTableRows } from './utils/tableUtils';
import Tooltip from '../../../../Shared/Tooltip';

function Table({ transactionData, isLoading, filters }) {
  const tableHeaders = TABLE_COLUMNS;
  const [tableData, setTableData] = useState([]);

  const dataRetentionDays = useSelector((state) => {
    return state.config.dataRetentionDays;
  });

  const adjustTableHeight = () => {
    const bodyContainer = document.getElementById('hotspot_table_body');
    const bodyStartPosition = bodyContainer.getBoundingClientRect().top;
    const viewportHeight = window.innerHeight;
    bodyContainer.style.maxHeight = `${viewportHeight - bodyStartPosition - 40}px`;
  };

  const openApplicationDetails = (transactionId, disabled) => {
    if (disabled) return;

    const redirectUrl = `${
      process.env.REACT_APP_AUDIT_PORTAL_BASE_URL
    }?recordPage=record&recordIdType=transactionId&recordId=${encodeURIComponent(
      transactionId.value
    )}&appid=${encodeURIComponent(filters.appId)}`;
    window.open(redirectUrl);
  };

  const isDisabled = (createdTimestamp) => {
    const today = new Date();
    const momentToday = moment(today);
    const momentCreatedAt = moment(createdTimestamp);

    return momentToday.diff(momentCreatedAt, 'days') > dataRetentionDays;
  };

  const rowEnterHandler = (transactionId, disabled) => {
    if (disabled) {
      const tooltipId = `analytics__tooltip_${transactionId}`;
      const tooltipElement = document.getElementById(tooltipId);
      tooltipElement.classList.add('active');
    }
  };

  const rowExitHandler = (transactionId) => {
    const tooltipId = `analytics__tooltip_${transactionId}`;
    const tooltipElement = document.getElementById(tooltipId);
    tooltipElement.classList.remove('active');
  };

  useEffect(() => {
    setTableData(generateTableRows(transactionData));
    adjustTableHeight();
    window.addEventListener('resize', adjustTableHeight);
  }, [transactionData]);

  const getTooltipText = () => {
    const today = new Date();
    const momentToday = moment(today);
    const dataAvailableFrom = momentToday.subtract(dataRetentionDays, 'days').format('DD/MM/YYYY');
    return `Data before ${dataAvailableFrom} is unavailable due to the data policy of your organisation`;
  };

  return (
    <div className="hotspot__side_panel__transactions_table">
      {isLoading && (
        <div className="hotspot__side_panel__transactions_table__loading_overlay">
          <Spinner />
        </div>
      )}
      <div
        id="hotspot_table_header"
        className="hotspot__side_panel__transactions_table_header_container">
        {tableHeaders.map((header) => {
          return (
            <div
              key={header.id}
              className={`hotspot__side_panel__transactions_table_header_cell ${header.id}`}>
              <h5>{header.label}</h5>
            </div>
          );
        })}
      </div>
      <div
        id="hotspot_table_body"
        className="hotspot__side_panel__transactions_table_body_container">
        {tableData &&
          tableData.map((row) => {
            const disabled = isDisabled(row.created_at.value);
            return (
              <div
                aria-hidden
                onMouseEnter={() => {
                  rowEnterHandler(row.transaction_id.value, disabled);
                }}
                id={row.transaction_id.value}
                onMouseLeave={() => {
                  rowExitHandler(row.transaction_id.value);
                }}
                onClick={() => openApplicationDetails(row.transaction_id, disabled)}
                key={row.transaction_id.value}
                className={`hotspot__side_panel__transactions_table_row_container ${
                  disabled ? 'disabled' : ''
                }`}>
                <Tooltip
                  tooltipId={`analytics__tooltip_${row.transaction_id.value}`}
                  text={getTooltipText()}
                />
                {Object.keys(row).map((key) => {
                  return (
                    <div
                      key={key}
                      className={`hotspot__side_panel__transactions_table_row_cell ${key}`}>
                      {row[key].componentRenderer()}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
}

Table.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  transactionData: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired
};

export default Table;
