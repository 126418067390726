/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isAuthenticated: false,
    userDetails: {
      permissions: [],
      role: '',
      email: '',
      name: '',
      appIds: {},
      useCases: {},
      useCaseAppIds: [],
      workflows: {}
    },
    credentials: {},
    currentAppId: '',
    currentUseCase: '',
    currentAppIdType: '',
    currentWorkflowId: '',
    currentClientId: '',
    currentEnvironment: 'STAGING'
  },
  reducers: {
    updateUserInfo: (state, action) => {
      const { permissions, role, email, name, appIds, useCases, clientId } = action.payload;
      state.userDetails.permissions = permissions;
      state.userDetails.role = role;
      state.userDetails.email = email;
      state.userDetails.name = name;
      state.userDetails.appIds = appIds;
      state.userDetails.useCases = useCases;
      state.currentClientId = clientId;
    },
    updateIsAuthenticated: (state, action) => {
      if (action.payload === false) {
        window.location.replace(process.env.REACT_APP_AUDIT_PORTAL_BASE_URL);
      }
      return { ...state, isAuthenticated: action.payload };
    },
    updateCurrentAppId: (state, action) => {
      const { appId, type } = action.payload;
      state.currentAppId = appId;
      state.currentAppIdType = type;
    },
    updateCurrentUseCase: (state, action) => {
      state.currentUseCase = action.payload;
      Object.keys(state.userDetails.useCases).forEach((element) => {
        if (element === action.payload)
          state.userDetails.useCaseAppIds = state.userDetails.useCases[action.payload];
      });
    },
    updateCurrentWorkflowId: (state, action) => {
      state.currentWorkflowId = action.payload;
    },
    updateWorkflows: (state, action) => {
      state.userDetails.workflows = action.payload;
    },
    updateCurrentClientId: (state, action) => {
      state.currentClientId = action.payload;
    },
    updateCurrentEnvironment: (state, action) => {
      state.currentEnvironment = action.payload;
    },
    updateCredentials: (state, action) => {
      state.credentials = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  updateUserInfo,
  updateIsAuthenticated,
  updateCurrentAppId,
  updateCurrentUseCase,
  updateWorkflows,
  updateCurrentWorkflowId,
  updateCurrentClientId,
  updateCredentials,
  updateCurrentEnvironment
} = userSlice.actions;
export default userSlice.reducer;
