import React, { useEffect, useRef, useState } from 'react';

import { addDays, endOfDay, startOfDay, endOfToday } from 'date-fns';
import PropTypes from 'prop-types';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';

import './DatePicker.css';
import calendar from '../../../assets/calendar.svg';
import { formatDateForDropdown, adjustStartDateForLeapYear } from '../../../utils/formatDate';

function DatePicker({ filters, changeDateRange }) {
  const datePickerRef = useRef();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [inputBoxValue, setInputBoxValue] = useState(0);

  const defineds = {
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date())
  };

  useEffect(() => {
    window.addEventListener('click', (event) => {
      if (
        event?.target !== datePickerRef?.current &&
        !datePickerRef?.current?.contains(event?.target)
      ) {
        setShowDatePicker(false);
      }
    });

    return window.removeEventListener('click', (event) => {
      if (
        event?.target !== datePickerRef?.current &&
        !datePickerRef?.current?.contains(event?.target)
      ) {
        setShowDatePicker(false);
      }
    });
  }, []);

  const toggleDatePicker = () => {
    setShowDatePicker((prev) => !prev);
  };

  const customStaticRanges = [
    ...defaultStaticRanges,
    {
      label: 'This Year',
      range() {
        const endDate = endOfToday();
        const startDate = adjustStartDateForLeapYear(endDate);

        return {
          startDate,
          endDate
        };
      },
      isSelected(range) {
        const endDate = endOfToday();
        const startDate = adjustStartDateForLeapYear(endDate);

        return (
          range.startDate.getTime() === startDate.getTime() &&
          range.endDate.getTime() === endDate.getTime()
        );
      }
    }
  ];

  return (
    <div
      ref={datePickerRef}
      role="presentation"
      onClick={toggleDatePicker}
      className="analytics-date-picker-container">
      <p>
        <img src={calendar} alt="calendar" />
        Date Range is &nbsp;
        <span>
          &quot; {formatDateForDropdown(filters?.dateRange?.startDate, filters?.dateRange?.endDate)}{' '}
          &quot;
        </span>
      </p>
      {showDatePicker && (
        <div
          role="presentation"
          onClick={(e) => e.stopPropagation()}
          className="analytics-date-picker">
          <DateRangePicker
            onChange={changeDateRange}
            ranges={[filters?.dateRange]}
            maxDate={new Date()}
            staticRanges={customStaticRanges}
            inputRanges={[
              {
                label: 'days up to today',
                range(value) {
                  setInputBoxValue(value);
                  if (value === 0 || value === 1) {
                    return {
                      startDate: defineds.startOfToday,
                      endDate: defineds.endOfToday
                    };
                  }
                  return {
                    startDate: addDays(
                      defineds.startOfToday,
                      (Math.max(Number(value), 1) - 1) * -1
                    ),
                    endDate: defineds.endOfToday
                  };
                },
                getCurrentValue(range) {
                  if (!range.startDate) return '∞';
                  return inputBoxValue;
                }
              }
            ]}
          />
        </div>
      )}
    </div>
  );
}

DatePicker.propTypes = {
  filters: PropTypes.object.isRequired,
  changeDateRange: PropTypes.func.isRequired
};

export default DatePicker;
