import React, { useState } from 'react';

import PropTypes from 'prop-types';

import smallArrow from '../../../assets/icons/smallArrow.svg';
import './Accordion.scss';

function Accordion({ title, content, show }) {
  const [isOpen, setIsOpen] = useState(show);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="accordion_container">
      <button
        type="button"
        className="accordion_button"
        onClick={toggleAccordion}
        aria-expanded={isOpen}>
        <img src={smallArrow} alt="arrow" className={isOpen ? 'open' : ''} />
        <span>{title}</span>
      </button>
      <div className={`accordion_content ${isOpen ? 'show' : ''}`}>
        <div className="accordion_inner_content">{content}</div>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired
};

export default Accordion;
