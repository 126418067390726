import React from 'react';

import PropTypes from 'prop-types';

import './AnalyticsLanding.css';

function AnalyticsLanding({
  image,
  imageStyles,
  heading,
  subheading,
  showRefreshButton,
  onRefreshButtonClick
}) {
  return (
    <div className="analytics-landing-div">
      <img style={{ ...imageStyles }} src={image} alt="Landing" />
      {heading && <h5>{heading}</h5>}
      {subheading && <p>{subheading}</p>}
      {showRefreshButton && (
        <button type="button" onClick={onRefreshButtonClick}>
          Refresh
        </button>
      )}
    </div>
  );
}

AnalyticsLanding.defaultProps = {
  imageStyles: {},
  showRefreshButton: false,
  onRefreshButtonClick: () => {}
};

AnalyticsLanding.propTypes = {
  image: PropTypes.any.isRequired,
  imageStyles: PropTypes.object,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  showRefreshButton: PropTypes.bool,
  onRefreshButtonClick: PropTypes.func
};

export default AnalyticsLanding;
