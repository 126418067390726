import React from 'react';

import { Spinner } from 'clm-components';
import './AnalyticsSpinner.css';

function AnalyticsSpinner() {
  return (
    <div className="analytics-spinner-container">
      <div className="analytics-loading-spinner">
        <Spinner />
        <p>Generating analytics, this might take a while, requesting you to be patient.</p>
      </div>
    </div>
  );
}

export default AnalyticsSpinner;
