import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { authenticateUserAPI, getUserAPI, logoutAPI } from '../api/user';
import { userAlertCodes } from '../constants/alertCodes';
import { updateIsAuthenticated, updateUserInfo } from '../reducers/user';
import store, { persistor } from '../store';

export const authenticateUser =
  () =>
  async ({ dispatch }) => {
    try {
      await authenticateUserAPI();
      dispatch(updateIsAuthenticated(true));
    } catch (error) {
      dispatch(updateIsAuthenticated(false));
      Sentry.captureException(error);
      toast.error(userAlertCodes.error.UNAUTHORIZED);
    }
  };

export const logout =
  () =>
  async ({ dispatch }) => {
    try {
      await logoutAPI();
    } catch (error) {
      Sentry.captureException(error);
      toast.error(userAlertCodes.error.LOGOUT);
    } finally {
      await persistor.purge();
      dispatch({ type: 'RESET' });
      window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}`);
    }
  };

export const checkTokenExpiry = async (statusCode) => {
  try {
    if (statusCode === 401) {
      await logout()({ dispatch: store.dispatch });
    }
  } catch (err) {
    Sentry.captureException(err);
    toast.error(userAlertCodes.error.LOGOUT);
  }
};

export const getUser =
  () =>
  async ({ dispatch }) => {
    try {
      const res = await getUserAPI();
      dispatch(updateUserInfo(get(res, 'data.result', {})));
    } catch (error) {
      Sentry.captureException(error);
      toast.error(userAlertCodes.error.UNAUTHORIZED);
      await logout()({ dispatch });
    }
  };
