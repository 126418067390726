/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState: {
    modules: {}
  },
  reducers: {
    updateModules: (state, action) => {
      const { modules } = action.payload;
      state.modules = modules;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateModules } = workflowSlice.actions;
export default workflowSlice.reducer;
