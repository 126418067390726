import { keys, keyBy, has, get } from 'lodash';

import { convertSecondsToMinutesSeconds } from '../../Components/PageComponents/Home/StepwiseAnalytics/utils';
import { getPercentage } from '../helpers';

const processStepwiseHotspots = ({ stepwiseMetrics, workflow }) => {
  const { modules } = workflow;

  const modulesById = keyBy(modules, 'id');

  const dropOffs = [];
  const highTimes = [];
  const highRetakes = [];
  const backPressedHotspot = [];

  const workflowStartNodeId = modules[0]?.id;
  const totalUsers = get(stepwiseMetrics, [workflowStartNodeId, 'totalTransactionsCount']);

  keys(stepwiseMetrics).forEach((nodeId) => {
    if (has(modulesById, nodeId)) {
      const { dropOff, medianTimeSpent, medianAttempts, backPressed } = stepwiseMetrics[nodeId];

      if (dropOff > 0) {
        dropOffs.push({
          moduleId: nodeId,
          dropOff,
          totalCount: totalUsers,
          percentage: getPercentage(dropOff, totalUsers)
        });
      }

      if (medianTimeSpent > 0) {
        highTimes.push({
          moduleId: nodeId,
          medianTime: medianTimeSpent
        });
      }

      if (medianAttempts > 0) {
        highRetakes.push({
          moduleId: nodeId,
          medianAttempts
        });
      }

      if (backPressed > 0) {
        backPressedHotspot.push({
          moduleId: nodeId,
          backPressed
        });
      }
    }
  });

  dropOffs.sort((a, b) => b.percentage - a.percentage);
  highTimes.sort((a, b) => b.medianTime - a.medianTime);
  highRetakes.sort((a, b) => b.medianAttempts - a.medianAttempts);
  backPressedHotspot.sort((a, b) => b.backPressed - a.backPressed);

  const dropOffsData = dropOffs.map((item) => {
    return {
      ...item,
      id: item.moduleId,
      label:
        get(modulesById, [item.moduleId, 'name']) || get(item, ['name']) || get(item, ['moduleId']),
      value: `${item.percentage}%`,
      width: getPercentage(item.percentage, dropOffs[0].percentage)
    };
  });

  const highTimeData = highTimes.map((item) => {
    return {
      ...item,
      id: item.moduleId,
      label:
        get(modulesById, [item.moduleId, 'name']) || get(item, ['name']) || get(item, ['moduleId']),
      width: getPercentage(item.medianTime, highTimes[0].medianTime),
      value: convertSecondsToMinutesSeconds(parseInt(item.medianTime / 1000, 10))
    };
  });

  const highRetakesData = highRetakes.map((item) => {
    return {
      ...item,
      id: item.moduleId,
      label:
        get(modulesById, [item.moduleId, 'name']) || get(item, ['name']) || get(item, ['moduleId']),
      width: getPercentage(item.medianAttempts, highRetakes[0].medianAttempts),
      value: item.medianAttempts
    };
  });

  const backPressedHotspotData = backPressedHotspot.map((item) => {
    return {
      ...item,
      id: item.moduleId,
      label:
        get(modulesById, [item.moduleId, 'name']) || get(item, ['name']) || get(item, ['moduleId']),
      width: getPercentage(item.backPressed, backPressedHotspot[0].backPressed),
      value: item.backPressed
    };
  });

  return { dropOffsData, highTimeData, highRetakesData, backPressedHotspotData };
};

export default processStepwiseHotspots;
