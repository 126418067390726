/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import * as d3 from 'd3';
import * as d3Sankey from 'd3-sankey';

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/sankey-diagram
function SankeyChart(
  {
    nodes, // an iterable of node objects (typically [{id}, …]); implied by links if missing
    links, // an iterable of link objects (typically [{source, target}, …])
    weightObj,
    percentages
  },
  {
    format = ',', // a function or format specifier for values in titles
    align = 'center', // convenience shorthand for nodeAlign
    nodeId = (d) => d.id, // given d in nodes, returns a unique identifier (string)
    nodeGroup, // given d in nodes, returns an (ordinal) value for color
    nodeGroups, // an array of ordinal values representing the node groups
    nodeLabel, // given d in (computed) nodes, text to label the associated rect
    // nodeTitle = (d) => `${d.id}\n${format(d.value)}`, // given d in (computed) nodes, hover text
    nodeAlign = align, // Sankey node alignment strategy: left, right, justify, center
    nodeWidth = 8, // width of node rects
    nodePadding = 50, // vertical separation between adjacent nodes
    nodeLabelPadding = 8, // horizontal separation between node and label
    nodeStrokeWidth, // width of stroke around node rects, in pixels
    nodeStrokeOpacity, // opacity of stroke around node rects
    nodeStrokeLinejoin, // line join for stroke around node rects
    linkSource = ({ source }) => source, // given d in links, returns a node identifier string
    linkTarget = ({ target }) => target, // given d in links, returns a node identifier string
    linkValue = ({ value }) => value, // given d in links, returns the quantitative value
    linkPath = d3Sankey.sankeyLinkHorizontal(), // given d in (computed) links, returns the SVG path
    // linkTitle = (d) => `${d.source.id} → ${d.target.id}\n${format(d.value)}`, // given d in (computed) links
    linkColor = 'source-target', // source, target, source-target, or static color
    linkStrokeOpacity = 0.35, // link stroke opacity
    linkMixBlendMode = 'multiply', // link blending mode
    colors, // array of colors
    width, // outer width, in pixels
    height, // outer height, in pixels
    marginTop = 0, // top margin, in pixels
    marginRight = 0, // right margin, in pixels
    marginBottom = 0, // bottom margin, in pixels
    marginLeft = 0 // left margin, in pixels
  } = {}
) {
  // Convert nodeAlign from a name to a function (since d3-sankey is not part of core d3).
  if (typeof nodeAlign !== 'function') {
    nodeAlign =
      {
        left: d3Sankey.sankeyLeft,
        right: d3Sankey.sankeyRight,
        center: d3Sankey.sankeyCenter
      }[nodeAlign] ?? d3Sankey.sankeyJustify;
  }

  // Compute values.
  const LS = d3.map(links, linkSource).map(intern);
  const LT = d3.map(links, linkTarget).map(intern);
  const LV = d3.map(links, linkValue);
  if (nodes === undefined) nodes = Array.from(d3.union(LS, LT), (id) => ({ id }));
  const N = d3.map(nodes, nodeId).map(intern);
  const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);

  // Replace the input nodes and links with mutable objects for the simulation.
  nodes = d3.map(nodes, (_, i) => {
    return { id: N[i], color: _.color };
  });
  links = d3.map(links, (_, i) => ({ source: LS[i], target: LT[i], value: LV[i] }));

  // Compute default domains.
  if (G && nodeGroups === undefined) nodeGroups = G;

  // Construct the scales.
  const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);

  // Compute the Sankey layout.
  d3Sankey
    .sankey()
    .nodeId(({ index: i }) => N[i])
    .nodeAlign(nodeAlign)
    .nodeWidth(nodeWidth)
    .nodePadding(nodePadding)
    .extent([
      [marginLeft, marginTop],
      [width - marginRight, height - marginBottom]
    ])({ nodes, links });

  // Compute titles and labels using layout nodes, so as to access aggregate values.
  if (typeof format !== 'function') format = d3.format(format);
  const Tl = nodeLabel === undefined ? N : nodeLabel == null ? null : d3.map(nodes, nodeLabel);
  // These are tool tips on hover which we are hiding because of default height
  // const Tt = nodeTitle == null ? null : d3.map(nodes, nodeTitle);
  // const Lt = linkTitle == null ? null : d3.map(links, linkTitle);

  // A unique identifier for clip paths (to avoid conflicts).
  const uid = `O-${Math.random().toString(16).slice(2)}`;

  const svg = d3
    .create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('overflow', 'visible')
    .attr('viewBox', [0, 0, width, height])
    .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

  const node = svg
    .append('g')
    .attr('stroke-width', nodeStrokeWidth)
    .attr('stroke-opacity', nodeStrokeOpacity)
    .attr('stroke-linejoin', nodeStrokeLinejoin)
    .selectAll('rect')
    .data(nodes)
    .join('rect')
    .attr('x', (d) => d.x0)
    .attr('y', (d) => d.y0)
    .attr('rx', 2)
    .attr('height', (d) => d.y1 - d.y0)
    .attr('width', (d) => d.x1 - d.x0)
    .style('fill', (d) => d.color);

  if (G) node.attr('fill', ({ index: i }) => color(G[i]));
  // if (Tt) node.append('title').text(({ index: i }) => Tt[i]);

  const link = svg
    .append('g')
    .attr('fill', 'none')
    .attr('stroke-opacity', linkStrokeOpacity)
    .selectAll('g')
    .data(links)
    .join('g')
    .style('mix-blend-mode', linkMixBlendMode);

  if (linkColor === 'source-target') {
    link
      .append('linearGradient')
      .attr('id', (d) => `${uid}-link-${d.index}`)
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', (d) => d.source.x1)
      .attr('x2', (d) => d.target.x0)
      .call((gradient) =>
        gradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', ({ source: { index: i } }) => nodes[i].color)
      )
      .call((gradient) =>
        gradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', ({ target: { index: i } }) => nodes[i].color)
      );
  }

  link
    .append('path')
    .attr('d', linkPath)
    .attr(
      'stroke',
      linkColor === 'source-target'
        ? ({ index: i }) => `url(#${uid}-link-${i})`
        : linkColor === 'source'
        ? ({ source: { index: i } }) => color(G[i])
        : linkColor === 'target'
        ? ({ target: { index: i } }) => color(G[i])
        : linkColor
    )
    .attr('stroke-width', ({ width }) => Math.max(1, width));
  // To remove the tooltip on hover, remove the below line.
  // .call(Lt ? (path) => path.append('title').text(({ index: i }) => Lt[i]) : () => {});

  if (Tl) {
    svg
      .append('g')
      .attr('font-family', 'Inter')
      .attr('font-size', 14)
      .attr('font-weight', '500')
      .attr('fill', '#05055266')
      .attr('letter-spacing', 'normal')
      .selectAll('text')
      .data(nodes)
      .join('text')
      .attr('x', (d) => d.x1 + nodeLabelPadding) // (d.x0 < width / 2 ? d.x1 + nodeLabelPadding : d.x0 - nodeLabelPadding))
      .attr('y', (d) => (d.y1 + d.y0) / 2 - 12)
      .attr('dy', '0.35em')
      .attr('text-anchor', () => 'start') // (d.x0 < width / 2 ? 'start' : 'end'))
      .text(({ index: i }) => Tl[i]);

    svg
      .append('g')
      .attr('font-family', 'Inter')
      .attr('font-size', 20)
      .attr('font-weight', '500')
      .attr('text-align', 'right')
      .attr('fill', '#050552cc')
      .selectAll('text')
      .data(nodes)
      .join('text')
      .attr('x', (d) => (d.x0 < width / 2 ? d.x1 + nodeLabelPadding : d.x1 + nodeLabelPadding))
      .attr('y', (d) => {
        return (d.y1 + d.y0) / 2 + 15;
      })
      .attr('dy', '0.35em')
      .attr('text-anchor', (d) => (d.x0 < width / 2 ? 'start' : 'start'))
      .text(({ index: i }) => weightObj[Tl[i]]);

    svg
      .append('g')
      .attr('font-family', 'Inter')
      .attr('font-size', 20)
      .attr('font-weight', '300')
      .attr('text-align', 'right')
      .attr('fill', '#050552cc')
      .selectAll('text')
      .data(nodes)
      .join('text')
      .attr('x', (d) => {
        const { length } = weightObj[Tl[d.index]].toString();
        let multiplier = 13;
        if (length < 2) multiplier = 17;
        if (length === 2) multiplier = 15;
        return d.x0 < width / 2
          ? d.x1 + nodeLabelPadding + length * multiplier
          : d.x1 + nodeLabelPadding + length * multiplier;
      })
      .attr('y', (d) => {
        return (d.y1 + d.y0) / 2 + 15;
      })
      .attr('dy', '0.35em')
      .attr('text-anchor', (d) => (d.x0 < width / 2 ? 'start' : 'start'))
      .text(({ index: i }) => `(${percentages[Tl[i]]}%)`);
  }

  function intern(value) {
    return value !== null && typeof value === 'object' ? value.valueOf() : value;
  }

  return Object.assign(svg.node(), { scales: { color } });
}

export default SankeyChart;
