import React from 'react';

import PropTypes from 'prop-types';

import ErrorDisplay from './ErrorDisplay';

function Body({ errorCode, selectedLinkData }) {
  if (errorCode) {
    return <ErrorDisplay errorCode={errorCode} />;
  }

  return (
    <div className="custom-report-body-container">
      <iframe id="metabase-question" title={selectedLinkData.title} src={selectedLinkData.link} />
    </div>
  );
}

Body.defaultProps = {
  errorCode: ''
};

Body.propTypes = {
  errorCode: PropTypes.string,
  selectedLinkData: PropTypes.object.isRequired
};

export default Body;
