import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import MinusIcon from '../../../../../../../assets/icons/minus.png';
import { updateActiveGoto } from '../../../../../../../reducers/stepwise';
import { hideGotoEdgesButton } from '../../../../../../../utils/stepwise';

function HideGoToEdges({ data }) {
  const dispatch = useDispatch();
  const activeGoto = useSelector((state) => state.stepwise.activeGoto);

  if (hideGotoEdgesButton(data, activeGoto)) {
    return (
      <img
        aria-hidden
        onClick={() => dispatch(updateActiveGoto({ activeGoto: '' }))}
        className="show_hidden_edges_icon"
        src={MinusIcon}
        alt="Show hidden edges"
      />
    );
  }

  return <div />;
}

HideGoToEdges.propTypes = {
  data: PropTypes.object.isRequired
};

export default HideGoToEdges;
