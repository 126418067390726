import { endOfDay, startOfDay, subDays } from 'date-fns';
import { isArray, isNull, isUndefined } from 'lodash';

import errorImage from '../assets/icons/error.svg';
import stepwiseLanding from '../assets/landing.png';
import AnalyticsLanding from '../Components/Shared/AnalyticsLanding';

export const getPercentage = (numerator, denominator) => {
  const integerNumerator = parseInt(numerator, 10);
  const integerDenominator = parseInt(denominator, 10);
  if (!integerNumerator || integerNumerator <= 0 || integerDenominator <= 0) return 0;
  const percentage = (integerNumerator / integerDenominator) * 100;
  return percentage.toFixed(2);
};

export const isInvalidNumber = (number) =>
  Number.isNaN(number) || number === undefined || number === null;

export const shouldRenderDropdown = (array, threshold = 1) => array.length > threshold;

export const isBlacklistedFilter = (filterKey, blacklistedFilters = []) =>
  blacklistedFilters.includes(filterKey);

export const returnDefaultDateRange = () => {
  return {
    startDate: startOfDay(subDays(new Date(), 14)),
    endDate: endOfDay(new Date())
  };
};

export const isNonEmptyArray = (array) => array && isArray(array) && array.length > 0;

export const isNullOrUndefined = (value) => isNull(value) || isUndefined(value);

export const isAPIError = (apiResponse) => apiResponse?.apiError === true;

export const getAnalyticsLanding = (showErrorContent, onRefreshButtonClick) => {
  if (showErrorContent) {
    return (
      <AnalyticsLanding
        image={errorImage}
        imageStyles={{
          height: '100px',
          marginBottom: '16px'
        }}
        heading="Whoops! Request timed out"
        subheading="We couldn't fetch your data in time. A quick refresh should fix it!"
        showRefreshButton
        onRefreshButtonClick={onRefreshButtonClick}
      />
    );
  }

  return (
    <AnalyticsLanding
      image={stepwiseLanding}
      imageStyles={{
        height: '120px',
        width: '160px'
      }}
      heading="Apply Filters to Get Insights"
      subheading="Apply relevant filters to get insights on your workflow's performance"
    />
  );
};
