import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/dropdownArrow.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/Search.svg';

import './SearchSelect.css';

function SearchSelect({ options, selectedOption, onOptionSelect, placeholder, inputPlaceholder }) {
  const dropdownRef = useRef();

  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const dropdownEventListener = (event) => {
      if (event.target !== dropdownRef?.current && !dropdownRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };

    window.addEventListener('click', (event) => dropdownEventListener(event));

    return () => {
      window.removeEventListener('click', (event) => dropdownEventListener(event));
    };
  }, []);

  const handleDropdownClick = () => {
    setShowOptions((prev) => !prev);
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handleOptionClick = (option) => {
    if (option.value === selectedOption?.value) return;
    onOptionSelect(option);
    setShowOptions(false);
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  // Filters out options on change of input value in search bar
  useEffect(() => {
    if (searchValue === '') {
      setFilteredOptions(options);
      return;
    }

    const filteredValues = options.filter(({ label }) =>
      label.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredOptions(filteredValues);
  }, [searchValue]);

  // Bring the selected option into view on expanding dropdown
  useEffect(() => {
    if (showOptions) {
      const selectedOptionElement = document.querySelector(
        '.search_select_option_container.selected'
      );
      selectedOptionElement?.scrollIntoView();
    }
  }, [showOptions]);

  return (
    <div className="search_select_container">
      <div className="search_select_dropdown_container">
        <div
          ref={dropdownRef}
          aria-hidden
          onClick={handleDropdownClick}
          className="search_select_dropdown">
          <p>{selectedOption?.label || placeholder}</p>
          <ArrowIcon className={`search_select_dropdown_icon ${showOptions ? 'rotate' : ''}`} />
        </div>
        {showOptions && (
          <div className="search_select_options_container">
            <div
              onClick={(event) => event.stopPropagation()}
              aria-hidden
              className="search_select_options_search_container">
              <SearchIcon />
              <input value={searchValue} onChange={handleSearch} placeholder={inputPlaceholder} />
            </div>
            <div className="search_select_list_container">
              {filteredOptions.map((option) => {
                return (
                  <div
                    key={option.id}
                    aria-hidden
                    onClick={() => handleOptionClick(option)}
                    className={`search_select_option_container ${
                      selectedOption?.value === option.value ? 'selected' : ''
                    }`}>
                    <p>{option.label}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

SearchSelect.defaultProps = {
  placeholder: 'Choose a value',
  inputPlaceholder: 'Search.....',
  selectedOption: null
};

SearchSelect.propTypes = {
  placeholder: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.object,
  onOptionSelect: PropTypes.func.isRequired
};

export default SearchSelect;
