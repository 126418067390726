import * as Sentry from '@sentry/react';
import axios from 'axios';
import { get } from 'lodash';

import { DEFAULT_RETENTION_DAYS } from '../constants';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/config`,
  withCredentials: true
});

const getRetentionDays = async (appId) => {
  try {
    const res = await axiosRequest({
      method: 'GET',
      url: '/dataRetentionDays',
      headers: {
        appId
      }
    });
    return get(res, 'data.result.dataRetentionDays', DEFAULT_RETENTION_DAYS);
  } catch (err) {
    Sentry.captureException(err);
    return { apiError: true };
  }
};

export default getRetentionDays;
