import { useCallback } from 'react';

import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';

import { DRAWER_TYPES, endStates, condition } from './constants';
import { getWorkflowModules, getWorkflows } from '../api/workflow';
import { updateWorkflows } from '../reducers/user';
import { updateModules } from '../reducers/workflow';

const useWorkflowHooks = () => {
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const dispatch = useDispatch();

  const addGenericModules = (modules) => {
    const temp = modules || {};

    Object.entries(temp).forEach(([key]) => {
      temp[key].nodeDisplayType = 'default';
      temp[key].DRAWER_TYPE = DRAWER_TYPES[key] || DRAWER_TYPES.api_drawer;
    });
    temp.condition = { ...condition };
    temp.condition.nodeDisplayType = 'condition';
    temp.condition.DRAWER_TYPE = DRAWER_TYPES.condition;

    temp.start.nodeDisplayType = 'input';
    temp.start.DRAWER_TYPE = DRAWER_TYPES.none;
    Object.entries(endStates).forEach(([key, value]) => {
      temp[key] = { ...value };
      temp[key].nodeDisplayType = 'output';
      temp[key].DRAWER_TYPE = DRAWER_TYPES.output;
    });
    return temp;
  };
  const fetchWorkflows = useCallback(async () => {
    try {
      const dataRes = await getWorkflows(currentAppId);
      dispatch(updateWorkflows(dataRes.workflows));
    } catch (error) {
      Sentry.captureException(error);
    }
  });

  const fetchModules = useCallback(async () => {
    try {
      const dataRes = await getWorkflowModules(currentAppId);
      const modules = addGenericModules(dataRes.modules);
      dispatch(updateModules({ modules }));
    } catch (error) {
      Sentry.captureException(error);
    }
  });

  return { fetchModules, fetchWorkflows };
};

export default useWorkflowHooks;
