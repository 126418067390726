import { get } from 'lodash';
import { useSelector } from 'react-redux';

const useGetUserPermissions = () => {
  const permissions = useSelector((state) => state.user.userDetails.permissions);
  const allowedPermissions = get(permissions, 'frontend.allowed', []);
  const disallowedPermissions = get(permissions, 'frontend.disallowed', []);
  return { allowedPermissions, disallowedPermissions };
};

export default useGetUserPermissions;
