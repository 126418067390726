import React from 'react';

import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

import './ComparisonCustomNode.css';
import HoverTooltip from '../HoverTooltip';

function ComparisonCustomNode({ data }) {
  return (
    <div id={data.id} className="comparison_custom_node_container">
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} id={data.id} />
      <div
        id={`${data.id}_comparsion_node`}
        style={{
          height: `${Math.abs(data.delta) * data.amplifierRatio}px`,
          width: `${Math.abs(data.delta) * data.amplifierRatio}px`,
          backgroundColor: data.color,
          borderColor: data.color
        }}
        className="comparison_custom_node_circle">
        <HoverTooltip
          label={data.label}
          parentId={`${data.id}_comparsion_node`}
          primaryValue={data.primaryValue}
          secondaryValue={data.secondaryValue}
          primaryPercentage={data.primaryPercentage}
          secondaryPercentage={data.secondaryPercentage}
        />
      </div>
      <p className="comparison_custom_node_label">{data.label}</p>
      <span className="comparison_custom_node_delta">{data.delta} %</span>
    </div>
  );
}

ComparisonCustomNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default ComparisonCustomNode;
