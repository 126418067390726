import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Body from './Body';
import Header from './Header';
import getRetentionDays from '../../../../../api/config';
import { updateDataRetentionDays } from '../../../../../reducers/config';
import SidePanel from '../../../../Shared/SidePanel';

import './HotspotSidePanel.css';

function HotspotSidePanel({
  showSidePanel,
  onSidePanelClose,
  filters,
  statusSelected,
  currentStatusHotspots,
  activeHotspot
}) {
  const dispatch = useDispatch();
  const getRetentionDaysData = async (appId) => {
    if (!appId) return;

    const retentionDays = await getRetentionDays(appId);
    dispatch(updateDataRetentionDays({ dataRetentionDays: retentionDays }));
  };

  useEffect(() => {
    getRetentionDaysData(filters.appId);
  }, [filters]);

  return (
    <SidePanel showSidePanel={showSidePanel} onSidePanelClose={onSidePanelClose}>
      <Header filters={filters} statusSelected={statusSelected} />
      <Body
        filters={filters}
        currentStatusHotspots={currentStatusHotspots}
        activeHotspot={activeHotspot}
        statusSelected={statusSelected}
      />
    </SidePanel>
  );
}

HotspotSidePanel.defaultProps = {
  showSidePanel: true,
  onSidePanelClose: () => {},
  currentStatusHotspots: []
};

HotspotSidePanel.propTypes = {
  showSidePanel: PropTypes.bool,
  onSidePanelClose: PropTypes.func,
  filters: PropTypes.object.isRequired,
  statusSelected: PropTypes.string.isRequired,
  currentStatusHotspots: PropTypes.array,
  activeHotspot: PropTypes.string.isRequired
};

export default HotspotSidePanel;
