/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { useDispatch } from 'react-redux';

// Connect hooks to actions
function CustomConnect(Component, { mapHooksToActions = {} }) {
  return function UpdatedComponent({ ...props }) {
    const dispatch = useDispatch();

    const hooksMappedActions = {};
    Object.entries(mapHooksToActions).forEach(([actionName, actionFunction]) => {
      hooksMappedActions[actionName] = (...args) => {
        const returnFunc = actionFunction(...args);
        return returnFunc({ dispatch });
      };
    });
    return <Component {...props} {...hooksMappedActions} />;
  };
}

export default CustomConnect;
