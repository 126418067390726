import React from 'react';

import { Outlet } from 'react-router-dom';

import './Layout.css';
import Navbar from '../Components/Shared/Navbar';

function Layout() {
  return (
    <div className="layout-parent-div">
      <div className="layout-navbar-div">
        <Navbar />
      </div>
      <div className="layout-component-div">
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
